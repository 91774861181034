<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_5">
      개인정보 수집이용 동의(판매자)<router-link to="" @click.native="close"
        ><img src="@/assets/images/sub/close.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="terms_common">
      <h5>
        리뷰앤메이크머니(주)가 운영하는 플랫폼 [리뷰하고 돈벌자]는 플랫폼과
        입점사, 고객 사이의 원활한 중개 및 거래행위를 위해 아래의 개인정보를
        수집합니다.
      </h5>

      <p>
        1. 리뷰앤메이크머니(주)가 판매자 정보를 수집/이용하려는 목적은 이용자
        식별, 계약이행을 위한 연락, 서비스 이용에 따른 정보의 제공, 민원 사항
        처리 등입니다.
      </p>
      <p>
        2. 수집 항목은 상호명, 회사 연락처, 회사 이메일, 대표자 이름, 담당자
        이름, 담당자 연락처, 담당자 이메일, 사업장소재지, 사업자 번호, 통신판매
        신고번호, 사업자등록증 사본입니다.
      </p>
      <p>
        3. 수집된 개인정보는 판매자가 채널을 삭제한 후 아래 회사의
        개인정보처리방침과 법령에 따라 해당 기간이 경과한 후 지체없이, 복구가
        불가능한 방식으로 파기됩니다.
      </p>
      <br />

      <h6>[회사의 개인정보처리 규정]</h6>
      <p>
        ① 부정거래 방지 및 금융사고 조사 등의 목적을 위해 판매자 정보: 6개월
        보관
      </p>
      <p>
        ② 부정가입 등의 부정이용기록 (가입인증 휴대전화번호 또는 DI): 회원탈퇴
        후 6개월 보관
      </p>
      <p>③ 결제도용 등의 부정거래기록: 3년 보관</p>
      <br />

      <h6>[전자상거래 등에서의 소비자보호에 관한 법률]</h6>
      <p>① 계약 또는 청약 철회 등에 관한 기록: 5년 보관</p>
      <p>② 대금결제 및 재화 등의 공급에 관한 기록: 5년 보관</p>
      <p>③ 소비자의 불만 또는 분쟁 처리에 관한 기록: 3년 보관</p>
      <p>④ 표시 광고에 관한 기록: 6개월 보관</p>
      <br />

      <h6>[전자금융거래법]</h6>
      <p>① 전자금융에 관한 기록: 5년 보관</p>
      <br />

      <h6>[전자문서 및 전자거래 기본법]</h6>
      <p>
        ① 인증서와 인증 업무에 관한 기록: 인증서 효력 상실일로부터 10년 보관
      </p>
      <br />

      <h6>[통신비밀보호법]</h6>
      <p>① 로그인 기록을 포함한 통신사실 확인자료: 3개월 보관</p>
      <br />

      <p>
        판매자는 위 개인정보 수집 이용 ․ 동의를 거부할 수 있습니다. 그러나
        거부할 경우, 램 가게에 입점하실 수 없습니다.
      </p>
    </div>
    <!---->
  </div>
</template>

<script>
export default {
  props: {
    isModals: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: false,
    });
  },
  methods: {
    close() {
      if (!this.isModals) {
        this.$router.back();
        return false;
      }
      this.$emit("open", "close");
      this.$store.dispatch("common/setFooter", {
        viewFooter: true,
      });
    },
  },
};
</script>

<style></style>
