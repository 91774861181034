<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      {{ this.sellerApplyInfo ? "램 가게 입점 신청 현황" : "램 가게 입점 신청"
      }}<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_11">
      <div class="top" v-if="!this.sellerApplyInfo">
        <ul>
          <li>
            판매자 채널을 등록하려면 먼저 램 가게에 입점해야 합니다. 램 가게
            입점 업체가 아님에도 판매자 채널을 개설하기 원하시는 분은 램과
            별도의 제휴 관계를 맺어야 합니다. 제휴사 신청은 홈페이지 하단에
            있습니다.
          </li>
          <li>
            아래 양식을 따라 램 가게 입점을 신청하면 램은 서류 검토와 심사를
            거쳐 승인 여부를 결정하게 됩니다. 입점 신청 후 마이페이지
            [리뷰어/판매자 채널 등록]에서 [판매자 채널 등록 신청하기]를 누르면
            심사과정을 알 수 있습니다.
          </li>
        </ul>
      </div>
      <div class="check_area" v-if="!this.sellerApplyInfo">
        <div class="check">
          <label
            ><input type="checkbox" name="" v-model="checkShop" />램 가게 판매
            이용약관 동의(필수)</label
          ><router-link to="" @click.native="open('shop')"
            ><img src="@/assets/images/sub/read_agree.png" alt=""
          /></router-link>
        </div>
        <div class="check">
          <label
            ><input type="checkbox" name="" v-model="checkPrivarcy" />개인정보
            수집이용 동의 (필수)</label
          ><router-link to="" @click.native="open('privacy')"
            ><img src="@/assets/images/sub/read_agree.png" alt=""
          /></router-link>
        </div>
        <div class="check">
          <label
            ><input type="checkbox" name="" v-model="checkService" />개인정보
            제공 동의 (필수) </label
          ><router-link to="" @click.native="open('service')"
            ><img src="@/assets/images/sub/read_agree.png" alt=""
          /></router-link>
        </div>
      </div>
      <ValidationObserver ref="obServer">
        <form @submit.prevent="regist">
          <div
            class="info_input"
            :style="this.sellerApplyInfo ? { borderTop: 0, padding: 0 } : ''"
          >
            <div class="box">
              <h3>상호명</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                name="상호명"
              >
                <div class="input">
                  <input
                    type="text"
                    name=""
                    maxlength="20"
                    v-model="providerName"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>회사 연락처</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                  phone: true,
                }"
                v-slot="{ errors }"
                name="회사 연락처"
              >
                <div class="input">
                  <input
                    type="text"
                    name=""
                    maxlength="11"
                    v-model="providerPhoneCk"
                  />
                </div>
                <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>회사 이메일</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                  email: true,
                }"
                v-slot="{ errors }"
                name="회사 이메일"
              >
                <div class="input">
                  <input
                    type="text"
                    name=""
                    maxlength="64"
                    v-model="providerEmail"
                  />
                </div>
                <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>담당자 이름</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                name="담당자 이름"
              >
                <div class="input">
                  <input type="text" name="" maxlength="20" v-model="name" />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>담당자 연락처</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                  cellphone: true,
                }"
                v-slot="{ errors }"
                name="담당자 연락처"
              >
                <div class="input">
                  <input
                    type="text"
                    name=""
                    v-model="cellphoneCk"
                    maxlength="11"
                  />
                </div>
                <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>담당자 이메일</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                  email: true,
                }"
                v-slot="{ errors }"
                name="담당자 이메일"
              >
                <div class="input">
                  <input type="text" name="" maxlength="64" v-model="email" />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>사업자 번호</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                  sellingLicense: true,
                }"
                v-slot="{ errors }"
                name="사업자 번호"
              >
                <div class="input">
                  <input
                    type="text"
                    name=""
                    v-model="businessNumberCk"
                    maxlength="12"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                name="통신판매 신고번호"
              >
                <h3>통신판매 신고번호</h3>
                <div class="input">
                  <input
                    type="text"
                    name=""
                    v-model="sellingLicense"
                    maxlength="50"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3 v-if="!this.sellerApplyInfo">사업자등록증 사본 첨부</h3>
              <div class="file" v-if="!this.sellerApplyInfo">
                <input type="file" name="" ref="business_doc_file" />
              </div>
              <div class="btn_area" v-else>
                <div class="btn left">
                  <router-link to="" @click.native="imgOpen"
                    >사업자등록증보기</router-link
                  >
                </div>
                <div class="btn right">
                  <router-link to="" @click.native="imgAttach"
                    >다시 제출</router-link
                  >
                  <input
                    type="file"
                    name=""
                    ref="business_doc_file_re"
                    style="display: none"
                    @change="docReUpload"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="sel_cate_before" v-if="!this.sellerApplyInfo">
            <h3>대표 카테고리 등록</h3>
            <!-- <select name="">
              <option value="">상품</option>
              <option value=""></option>
            </select> -->
            <div class="list" ref="list">
              <div
                class="box"
                v-for="(category, index) in categories"
                :key="category.category_code"
              >
                <router-link
                  to=""
                  @click.native="selectCategory(category.category_code, index)"
                  >{{ category.title }}</router-link
                >
              </div>
            </div>
            <div class="txt">
              대표 카테고리를 등록해야 검색시 램 가게 상호명이 카테고리별 검색
              리스트에 노출됩니다. 주력 상품이 많은 카테고리를 대표 카테고리로
              등록하세요. 대표 카테고리로 등록하지 않은 카테고리의 상품도 판매가
              가능합니다. 대표 카테고리 등록은 오직 검색을 위한 것입니다.
            </div>
          </div>
          <div class="sel_cate_after" v-else>
            <h3>대표 카테고리</h3>
            <p>{{ sellerApplyInfo.title }}</p>
            <div class="status">
              <div
                :class="
                  sellerApplyInfo.apply_result === 'apply'
                    ? 'status_type_2'
                    : 'status_type_1'
                "
              >
                현재 접수가 완료되었습니다.
              </div>
              <div class="status_ico">
                <img src="@/assets/images/sub/status_ico.png" alt="" />
              </div>
              <div
                :class="
                  sellerApplyInfo.apply_result === 'progress'
                    ? 'status_type_2'
                    : 'status_type_1'
                "
              >
                현재 서류가 검토 중입니다.
              </div>
              <div class="status_ico">
                <img src="@/assets/images/sub/status_ico.png" alt="" />
              </div>
              <div
                :class="
                  sellerApplyInfo.apply_result === 'decide'
                    ? 'status_type_2'
                    : 'status_type_1'
                "
              >
                램 가게 입점 신청이 승인되었습니다.
              </div>
            </div>
            <div
              class="status"
              v-if="sellerApplyInfo.apply_result === 'reject'"
            >
              <div class="status_type_2 reject">
                램 가게 입점 신청이 거절 되었습니다. 거절 사유는 아래와
                같습니다.
              </div>
              <div class="reject_box">
                {{ sellerApplyInfo.memo }}
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div class="rn_my_box_btn yellow" v-if="!this.sellerApplyInfo">
      <div>
        <router-link to="" @click.native="regist"
          >램 가게 입점 신청하기</router-link
        >
      </div>
    </div>
    <ImageModal
      :img="sellerApplyInfo.business_doc_file"
      v-if="isOpen"
      @imgOpen="imgOpen"
    />
    <RammShopTerm class="modals" v-if="isShop" :isModals="true" @open="open" />
    <SellerProvidePrivacy
      class="modals"
      v-if="isPrivacy"
      :isModals="true"
      @open="open"
    />
    <SellerAgreePrivacy
      class="modals"
      v-if="isService"
      :isModals="true"
      @open="open"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ImageModal from "@/components/util/ImageModal";
import SellerProvidePrivacy from "@/components/policy/SellerProvidePrivacy";
import SellerAgreePrivacy from "@/components/policy/SellerAgreePrivacy";
import RammShopTerm from "@/components/policy/RammShopTerm";
export default {
  data() {
    return {
      isOpen: false,
      providerName: null,
      providerPhone: null,
      name: null,
      cellphone: null,
      email: null,
      providerEmail: null,
      businessNumber: null,
      sellingLicense: null,
      mainCategory: null,
      categories: [],
      categoryCode: null,
      image: null,
      checkShop: false,
      checkPrivarcy: false,
      checkService: false,
      isPrivacy: false,
      isService: false,
      isShop: false,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.getSellerApplyStatus();
  },
  mounted() {
    this.getMainCategory();
  },
  methods: {
    open(type) {
      document.querySelector("html").style.overflow = "hidden";
      switch (type) {
        case "service":
          this.isShop = false;
          this.isPrivacy = false;
          this.isService = true;
          break;
        case "privacy":
          this.isShop = false;
          this.isService = false;
          this.isPrivacy = true;
          break;
        case "shop":
          this.isService = false;
          this.isPrivacy = false;
          this.isShop = true;
          break;
        default:
          document.querySelector("html").style.overflow = "";
          this.isShop = false;
          this.isPrivacy = false;
          this.isService = false;
          break;
      }
      console.log(document.querySelector("html").style.overflow);
    },
    imgOpen() {
      this.isOpen = !this.isOpen;
    },
    checkImgFile() {
      if (!this.image) {
        this.$toast.default("사업자등록증 사본 이미지를 첨부 해주세요");
        return false;
      }
      let ext = this.image.name.split(".").pop().toLowerCase();
      const allow_ext = ["jpg", "png", "jpeg", "gif"];
      const ext_check = allow_ext.some((item) => item === ext);
      if (!ext_check) {
        this.$toast.default("허용되지 않는 파일 입니다.");
        return false;
      }
      return true;
    },
    selectCategory(code, index) {
      const boxs = [...this.$refs.list.children];
      if (boxs.length > 0) {
        boxs.forEach((box) => {
          box.classList.remove("on");
        });
        boxs[index].classList.add("on");
        this.categoryCode = code;
      }
    },
    imgAttach() {
      this.$refs.business_doc_file_re.click();
    },
    docReUpload() {
      this.image = this.$refs.business_doc_file_re.files[0];
      if (this.checkImgFile()) {
        this.$refs.business_doc_file_re.value = null;
      }
      this.$confirm("사업자 등록증 사본을 다시 첨부 하시겠습니까?")
        .then(async () => {
          let data = new FormData();
          data.append("filedata", this.image);
          data.append("seq", this.sellerApplyInfo.seq);
          await this.$store.dispatch("mypage/docReUpload", data);
          this.$toast.default(this.$store.state.mypage.msg);
        })
        .catch(() => {
          return false;
        });
    },
    async getMainCategory() {
      await this.$store.dispatch("shop/getMainCategory");
      this.categories = this.$store.state.shop.mainCategory;
    },
    async regist() {
      const validate = await this.$refs.obServer.validate();
      if (!this.checkShop) {
        this.$toast.default("램 가게 약관에 동의해주세요.");
        return false;
      }
      if (!this.checkPrivarcy) {
        this.$toast.default("개인정보 수집이용에 동의해주세요.");
        return false;
      }
      if (!this.checkService) {
        this.$toast.default("개인정보 제공에 동의해주세요.");
        return false;
      }
      if (validate) {
        this.image = this.$refs.business_doc_file.files[0];
        if (!this.checkImgFile()) {
          this.$refs.business_doc_file.value = null;
          return false;
        }
        if (!this.categoryCode) {
          this.$toast.default("대표 카테고리를 선택해 주세요.");
          return false;
        }
        let data = new FormData();
        data.append("provider_name", this.providerName);
        data.append("provider_phone", this.providerPhone);
        data.append("provider_email", this.providerEmail);
        data.append("name", this.name);
        data.append("cellphone", this.cellphone);
        data.append("email", this.email);
        data.append("business_number", this.businessNumber);
        data.append("selling_license", this.sellingLicense);
        data.append("category_code", this.categoryCode);
        data.append("filedata", this.image);
        await this.$store.dispatch("mypage/sellerApply", data);
        this.$toast.default(this.$store.state.mypage.msg);
        if (this.$store.state.mypage.result) {
          this.$router.push("/mypage/my_info");
        }
      }
    },
    async getSellerApplyStatus() {
      await this.$store.dispatch("mypage/getSellerApplyStatus");
      if (this.sellerApplyInfo) {
        this.providerName = this.sellerApplyInfo.provider_name;
        this.providerPhone = this.sellerApplyInfo.provider_phone.replace(
          /-/g,
          ""
        );
        this.name = this.sellerApplyInfo.name;
        this.cellphone = this.sellerApplyInfo.cellphone.replace(/-/g, "");
        this.email = this.sellerApplyInfo.email;
        this.providerEmail = this.sellerApplyInfo.provider_email;
        this.businessNumber = this.sellerApplyInfo.business_number.replace(
          /-/g,
          ""
        );
        this.sellingLicense = this.sellerApplyInfo.selling_license;
      }
    },
  },
  computed: {
    ...mapState("mypage", ["sellerApplyInfo", "result"]),
    // ...mapState("mypage", ["result"]),
    businessNumberCk: {
      get() {
        return this.businessNumber;
      },
      set(businessNumber) {
        this.businessNumber = businessNumber.replace(/[^0-9]/g, "");
      },
    },
    cellphoneCk: {
      get() {
        return this.cellphone;
      },
      set(cellphone) {
        this.cellphone = cellphone.replace(/[^0-9]/g, "");
      },
    },
    providerPhoneCk: {
      get() {
        return this.providerPhone;
      },
      set(providerPhone) {
        this.providerPhone = providerPhone.replace(/[^0-9]/g, "");
      },
    },
  },
  components: {
    ImageModal,
    SellerProvidePrivacy,
    SellerAgreePrivacy,
    RammShopTerm,
  },
  beforeDestroy() {
    document.querySelector("html").style.overflow = "";
  },
};
</script>

<style lang="scss" scoped>
.rn_my_box_11 {
  .sel_cate_after {
    .status {
      .status_type_2 {
        &.reject {
          text-align: left;
          line-height: 30px;
        }
      }
    }
  }
  .btn_area {
    display: flex;
    .btn {
      a {
        display: inline-block;
        width: auto;
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        padding: 0 10px;
      }
      &.left {
        a {
          background: #484848;
          color: white;
        }
      }
      &.right {
        margin-left: auto;
        a {
          background: #d3d3d3;
          color: #484848;
        }
      }
    }
  }
  .reject_box {
    margin-top: 10px;
    border: 1px solid #d3d3d3;
    padding: 5px;
    box-sizing: border-box;
    line-height: 18px;
  }
}
.check_area {
  border-top: 1px solid #d3d3d3;
  margin-bottom: 10px;
  .check {
    padding-top: 10px;
    input {
      margin-right: 5px;
    }
    a {
      float: right;
    }
  }
}
.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  overflow: scroll;
  height: calc(100% - 40px);
}
</style>
