import { render, staticRenderFns } from "./SellerRegister.vue?vue&type=template&id=3010bb3b&scoped=true&"
import script from "./SellerRegister.vue?vue&type=script&lang=js&"
export * from "./SellerRegister.vue?vue&type=script&lang=js&"
import style0 from "./SellerRegister.vue?vue&type=style&index=0&id=3010bb3b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3010bb3b",
  null
  
)

export default component.exports