<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_5">
      램 가게 판매 이용약관<router-link to="" @click.native="close"
        ><img src="@/assets/images/sub/close.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="terms_common">
      <h4>램 가게 판매 이용약관</h4>

      <h6>제1조 (목적)</h6>

      <p>
        주식회사 리뷰앤메이크머니(이하 '회사')가 제공하는 서비스를 이용해주셔서
        감사합니다. 이 약관은 회사와 '램 가게 판매자센터'에 입점하는 입점사
        사이의 권리·의무 및 판매 관련 사항들을 확정하고 이의 이행을 통해 양자
        모두의 이익을 증진하는 것을 그 목적으로 합니다.
      </p>
      <br />

      <h6>제2조 (용어의 정의)</h6>
      <p>
        ① 이 약관에서 사용하는 용어의 정의는 아래 내용 외에 ‘램 이용약관’의 용어
        정의를 따릅니다.
      </p>
      <p>
        ② 램(RAMM): ‘램’은 ‘Review and Make Money(리뷰하고 돈벌자)’의 약칭이며
        인터넷 게시판 겸 사이버몰로서 가상의 영업장입니다. 인터넷 플랫폼
        ‘램(리뷰하고 돈벌자)’은 램 가게 판매자센터, 램 가게, 램 장터, 리뷰
        놀이터, 리뷰 거래소 등 다양한 시스템을 제공합니다. 이 가상의 영업장은
        https://reviewnmakemoney.com, https://m.reviewnmakemoney.com 등 회사가
        운영하는 컴퓨터 웹사이트 및 모바일 웹과 앱 등 모두를 포함합니다.
      </p>
      <p>
        ③ 램 가게 판매자센터: ‘램 가게 판매자센터’란 본 인터넷 플랫폼에 입점한
        업체들이 자신들의 상품/서비스를 등록, 관리하는 가상의 공간을 의미합니다.
        입점 업체들에 의해 램 가게 판매자센터에 등록된 상품/서비스 정보는 램
        가게와 램 장터에 노출됩니다.
      </p>
      <p>
        ④ 입점사 관리자 툴: ‘입점사 관리자 툴’은 램 가게 판매자센터에 입점사별로
        할당된 가상의 공간입니다. 입점사는 로그인한 후 이곳에서 자신의
        상품/서비스를 등록, 관리합니다.
      </p>
      <p>
        ⑤ 램 가게(RAMM Store): ‘램 가게’는 ‘램 장터’에 위치한 가상의 상점입니다.
        ‘램 가게 판매자센터’가 입점사들이 상품/서비스를 등록, 관리하는 가상의
        공간이라면, ‘램 가게’는 입점사들이 해당 상품/서비스를 소비자들에게 노출,
        판매하는 가상의 공간입니다. 램 가게에서 입점 업체는 수동적으로는
        리뷰어들의 리뷰를 통해 자신들의 상품이나 서비스를 홍보하게 되며
        능동적으로는 리뷰 거래소를 통해 자신들의 상품이나 서비스를 홍보할 수도
        있습니다.
      </p>
      <p>
        ⑥ 램 장터(RAMM Market): ‘램 장터’는 오픈 마켓으로서 제휴사 또는 램 가게
        입점 업체가 상품/서비스 정보를 제공하는 가상의 공간 또는 제공된
        상품/서비스 정보가 공개되는 가상의 공간입니다. 일반 소비자들은 직접 램
        장터를 방문해 상품/서비스 정보를 살펴보고 상품/서비스의 구매를 결정할 수
        있으며, 구매 결정 후 램 가게나 제휴사 홈페이지로 이동해 상품 및 서비스를
        구매하게 됩니다.
      </p>
      <p>
        ⑦ 리뷰 놀이터(Review Playground): ‘리뷰 놀이터’는 상품/서비스에 대한
        리뷰가 게시되는 가상의 공간입니다. 리뷰어는 램 장터의 연결화면 또는 램
        가게 상세페이지의 상품/서비스에 대해 [리뷰하기] 버튼을 눌러 해당
        상품/서비스를 리뷰하게 되며, 해당 리뷰는 리뷰 놀이터에 게시됩니다.
        소비자들은 이 리뷰를 통해 상품/서비스 정보를 살펴보고 상품/서비스의
        구매를 결정할 수 있으며, 구매 결정 후 램 가게나 제휴사 홈페이지로 이동해
        상품 및 서비스를 구매하게 됩니다.
      </p>
      <p>
        ⑧ 리뷰 거래소(Review Service Exchange): ‘리뷰 거래소’는 리뷰 서비스가
        거래되는 가상의 영업장입니다. 리뷰 거래소에서 리뷰 서비스를 사고파는
        당사자는 제휴사․판매자․리뷰거래소 회원(리뷰 서비스 구매자) 대
        리뷰어입니다.
      </p>
      <p>
        ⑨ 거래시스템: ‘거래시스템’은 램 가게 판매자센터, 램 가게, 램 장터, 리뷰
        놀이터, 리뷰 거래소 등 램 플랫폼 안에서 상품/서비스 거래와 관련된 모든
        기능을 의미합니다.
      </p>
      <p>
        ⑩ 판매자: 판매자 또는 판매자 회원은 ‘램 가게 판매자센터’에 입점해 ‘램
        가게’ 판매자 회원으로 등록된 업체를 의미합니다. 해당 입점사가 ‘램 가게
        판매자센터’에 입점하는 과정을 마치면 해당 입점사는 램 가게 판매자
        회원으로도 자동 등록되며, 램 가게를 통해 다양한 상거래 활동을 하게
        됩니다.
      </p>
      <p>
        ⑪ 제휴사: 제휴사는 ‘램 가게 판매자센터’에 입점하지 않았으나 회사와 제휴
        관계를 맺고 램 장터에 상품/서비스 정보를 제공하는 업체를 의미합니다.
      </p>
      <p>
        ⑫ 인프라 사용료: 인프라 사용료란 회사가 제공하는 서비스의 대가로
        판매자나 제휴사가 회사에 지불하는 금액입니다.
      </p>
      <p>
        ⑬ 램 이용약관: ‘램 이용약관’은 회사의 플랫폼 ‘리뷰하고 돈벌자’에
        일반회원으로 가입하는 분들과 회사 사이에 체결되는 계약서입니다. ‘램
        이용약관’은 ‘램 가게 판매 이용약관’과 다릅니다.
      </p>
      <p>
        ⑭ 램 가게 판매 이용약관: ‘램 가게 판매 이용약관’은 ‘램 가게
        판매자센터’에 입점하는 입점사(램 가게 판매자)와 회사 사이에 체결되는
        계약서입니다.
      </p>
      <br />
      <h6>제3조 (약관의 게시와 효력 및 개정)</h6>
      <p>
        ① 회사는 판매자 회원이 쉽게 접근하고 숙지할 수 있도록 본 약관을 램 가게
        판매자센터 초기화면에 게시합니다.
      </p>
      <p>
        ② 회사는 이 약관에 규정되지 않은 세부적인 내용을 ‘램 가게 운영정책’으로
        제정, 운영할 수 있으며, 이 경우 해당 운영정책을 ‘입점사 관리자 툴
        게시판’(이하 ‘입점사 공지 게시판’)에 게시합니다. 해당 운영정책은 이
        약관과 함께 본 서비스 이용계약의 일부를 구성합니다.
      </p>
      <p>
        ③ 회사는 관련 법령에 따라 특정 상품/서비스의 판매에 대해 개별약관을
        제정할 수 있으며 판매자 회원이 개별약관에 동의하는 경우 개별약관은 본
        서비스 이용계약의 일부를 구성합니다. 개별약관과 본 약관이 상충하는 경우
        개별약관이 우선합니다.
      </p>
      <p>
        ④ 일반회원 가입용 ‘램 이용약관’ 중에서 판매 서비스에 적용될 수 있는
        항목은 본 조 ③항과 동일한 성격을 갖습니다. 본 약관에 동의하고 판매자
        회원으로 가입하시려는 분은 본 항의 내용에도 동의하며 그에 따라
        일반회원용 ‘램 이용약관’을 숙지하고 그 규정을 이행한다는 사실을
        확인합니다.
      </p>
      <p>
        ⑤ 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관을
        개정할 수 있습니다.
      </p>
      <p>
        ⑥ 회사가 본 약관 및 램 가게 운영정책, 개별약관을 개정할 경우, 회사는
        개정약관에 개정약관의 적용일자 및 개정사유를 명시하여 현행약관과 함께 램
        가게 판매자센터 초기화면 또는 입점사 관리자 툴 게시판에 공지합니다. 공지
        기간은 개정약관 적용일자 7일 전부터 적용일자 전일까지입니다. 다만,
        회원에게 불리하게 약관이 변경되는 경우 회사는 개정약관 적용일자 30일
        이전부터 적용일자 전일까지 공지하며, 회원이 개정 전후의 내용을 명확히 알
        수 있도록 수정된 항목에 대해 양쪽의 내용을 비교해 명시합니다.
      </p>
      <p>
        ⑦ 본 조 ⑥항에 의해 변경된 약관은 관련 법령에 특별한 규정이나 기타
        부득이한 사유가 없는 한 그 적용일자 이전으로 소급 적용되지 않습니다.
      </p>
      <p>
        ⑧ 판매자 회원이 본 조 ⑥항에 따른 개정약관 공지 기간에 명확히 거부 의사를
        밝히지 않는 경우, 공지가 끝난 후 판매자 회원은 개정약관에 동의하는
        것으로 간주됩니다. 회사가 본 조 ⑥항의 조치를 취했음에도 회원이 이를 알지
        못해 발생하는 회원의 피해에 대해서는 회사가 책임을 지지 않습니다.
      </p>
      <p>
        ⑨ 회원이 개정약관에 동의하지 않는 경우 회사는 회원에게 개정약관을 강제할
        수 없으며, 이 경우 회원은 개정약관의 공지 기간에 이용계약 해지 및
        회원탈퇴를 요청할 수 있습니다. 아울러 회사가 기존약관을 적용할 수 없는
        특별한 사정이 있는 경우 회사는 회원과의 이용계약을 해지할 수 있습니다.
      </p>
      <p>
        ⑩ 본 약관과 관련해 양 당사자의 합의하에 추가로 작성된 계약서, 협정서,
        약정서 등과 회사의 정책변경으로 개정된 운영정책 및 개별약관, 제․개정된
        관련 법령 및 공공기관의 고시․지침 등에 따라 회사가 램 가게 판매자센터에
        공지하는 내용도 이용계약의 일부를 구성합니다.
      </p>
      <p>
        ⑪ 이 약관에서 정하지 않은 사항과 이 약관의 해석에 대해서는 관련 법령 및
        상관례에 따릅니다.
      </p>
      <p>
        ⑫ 이 약관의 특정 조항이 대한민국의 강행법규와 상충하는 경우 그
        강행법규에 따릅니다. 이 경우 다른 조항의 효력에는 영향을 미치지
        않습니다.
      </p>
      <br />
      <h6>제4조 (램 가게 판매자센터 및 거래시스템의 운영)</h6>
      <p>
        ① 회사는 당사 플랫폼 ‘리뷰하고 돈벌자’에서 판매자 회원들이 소비자들을
        상대로 상품/서비스를 판매할 수 있도록 이를 위한 관리자 툴 ‘램 가게
        판매자센터’를 운영합니다.
      </p>
      <p>
        ② 판매자 회원들이 ‘램 가게 판매자센터’에 등록한 상품/서비스는 ‘리뷰하고
        돈벌자’의 ‘램 가게’ 또는 ‘램 장터’에 노출되며, 노출된 상품/서비스는
        리뷰어들에 의해 리뷰되거나 소비자들에게 판매됩니다. 회사는 이를 조정,
        중개하기 위한 시스템을 제공, 운영, 관리합니다.
      </p>
      <p>
        ③ 회사는 판매자가 램 가게 판매자센터에 등록한 상품이나 서비스 콘텐츠
        또는 해당 상품 및 콘텐츠에 대한 리뷰를 램 장터나 리뷰 놀이터에 회사가
        정한 기준과 순서에 따라 노출할 수 있습니다. 이는 상품/서비스 정보를
        효과적으로 전달하고, 해당 상품/서비스의 마케팅, 홍보, 광고, 유통 등을
        원활히 하기 위함입니다.
      </p>
      <p>
        ④ 회사는 램 가게 판매자센터에 등록된 상품 및 서비스 콘텐츠를 회사가
        승인한 포털이나 가격 비교 웹사이트, 소셜 네트워크 등에 공개할 수
        있습니다. 이는 해당 상품/서비스의 판매를 촉진하기 위함입니다.
      </p>
      <p>
        ⑤ 회사는 판매자와 구매자 간의 거래에 필요한 결제 시스템을 제공하며 해당
        거래에 대해 판매자를 대신해 상품/서비스 판매대금을 수령하고, 본 약관과
        램 가게 운영정책에 따라 판매자에게 인프라 사용료 등을 제외한 해당 대금을
        지급합니다.
      </p>
      <p>
        ⑥ 회사는 램 가게 판매자센터의 상품/서비스 카테고리를 수정, 추가, 삭제할
        수 있습니다. 이 경우 회사는 시행일, 내용, 방법 등을 시행일 30일 전부터
        전일까지 ‘입점사 공지 게시판’에 공지합니다.
      </p>
      <p>
        ⑦ 회사는 ‘전자상거래 등에서의 소비자보호에 관한 법률(이하
        ’전자상거래법‘)’ 제13조 ①항을 따라 램 가게 입점 업체의 판매자 정보를 램
        가게 상품 상세페이지와 연결화면에 게시합니다.
      </p>
      <p>
        ⑧ 회사는 판매자가 램 가게 판매자센터 및 램 가게를 원활하게 이용할 수
        있도록 판매자에게 필요한 정보 등을 제공하며, 필요한 경우 판매자에게 당사
        플랫폼 이용에 대한 의견이나 정보 제공 등을 요청할 수 있습니다.
      </p>
      <br />
      <h6>제5조 (거래시스템에서의 회사 역할 및 책임 소재)</h6>
      <p>
        ① 회사는 통신판매중개업자로서 본 약관 제4조 ①항, ②항, ⑤항에 대해 책임을
        부담합니다. 그러나 회사의 이러한 행위는 상품/서비스의 판매나 구매,
        리뷰의 게시 및 리뷰 서비스의 거래 등과 관련해 판매자, 리뷰어,
        리뷰이용자, 소비자들을 대리하는 것이 아닙니다.
      </p>
      <p>
        ② 회사는 회사가 제공하는 서비스를 통해 이루어지는 회원 간의 거래 등
        회원들의 모든 행위와 관련해 판매 의사 또는 구매 의사의 여부 및 진위,
        등록된 상품/서비스의 품질, 완전성, 안전성, 적법성 또는 타인의 권리에
        대한 비침해성, 리뷰어가 게시한 리뷰의 진실성, 소비자들이 제공한 소비
        자료의 진정성, 적법성 등을 보증하지 않으며, 이로부터 발생하는 모든 위험
        및 책임은 해당 당사자가 부담해야 합니다.
      </p>
      <p>
        ③ 회사는 회사의 서비스를 통한 상품/서비스의 판매 효과에 대해 보증하지
        않으며, 판매자 회원은 판매 효과 미흡 등을 이유로 회사에 어떠한 책임도
        물을 수 없습니다.
      </p>
      <p>
        ④ 회사는 통신판매 당사자가 아닌 통신판매 중개자입니다. 상품/서비스
        거래로 인해 발생하는 분쟁은 통신판매 당사자인 판매자가 책임집니다.
        회사는 당사자 간 분쟁 해결이 어려워 회사가 운영하는 분쟁조정센터 또는
        고객센터에 당사자 중 일방이 분쟁 해결을 요청할 경우 예외적으로 분쟁에
        개입할 수 있으며, 판매자는 분쟁조정센터 또는 고객센터의 결정을 최대한
        성실하게 존중해야 합니다.
      </p>
      <p>
        ⑤ 회사는 아래 각호의 경우, 판매자가 게시한 상품/서비스 정보를 수정하거나
        삭제할 수 있습니다. 이 경우 판매자는 회사에 그로 인한 손해 배상을 청구할
        수 없습니다. <br />
        1. 판매자가 게시한 상품/서비스 정보가 본 약관, 운영정책, 개별약관을
        위반한 경우 <br />2. 판매자가 게시한 상품/서비스 정보가 관련 법령이나
        규정을 위반한 경우 <br />3. 판매자가 게시한 상품/서비스 정보가 제3자의
        명예나 권리 등을 침해한 경우 <br />4. 판매자가 게시한 상품/서비스 정보가
        제3자와 분쟁을 일으킨 또는 일으킬 소지가 있는 경우 <br />5. 판매자가
        게시한 상품/서비스 정보가 방송통신심의위원회 등 관련 기관, 소비자 단체,
        언론 등에 의해 문제가 되는 경우 <br />6. 판매자가 게시한 상품/서비스
        정보가 회사에 부정적인 영향을 미치는 경우 <br />7. 그 외 다양한 형태의
        수정, 삭제 요청이 합리적이라고 회사가 판단하는 경우
      </p>
      <p>
        ⑥ 본 조 ①항, ②항, ④항, ⑤항과 관련해 회사가 제3자에게 손해를 배상했거나
        비용을 지출한 경우 회사는 이에 대해 책임이 있는 판매자나 리뷰어 또는
        구매자에게 구상권을 행사할 수 있습니다.
      </p>
      <p>
        ⑦ 램 가게 판매자센터의 운영과 관련해 회사의 고의나 중과실로 인한 손해는
        회사가 책임을 부담합니다.
      </p>
      <br />
      <h6>제6조 (이용계약의 성립 및 유지)</h6>
      <p>
        ① 회사가 램 플랫폼의 판매자들을 위해 제공하는 서비스를 이용하고자 하시는
        분(이하 ‘등록신청자’)은 먼저 ‘리뷰하고 돈벌자’에 일반회원으로 가입하신
        후 마이페이지의 ‘판매자 채널 등록 신청하기’를 통해 ‘램 가게 입점 신청’을
        해야 합니다. 등록신청자는 램 가게 입점 신청 시 회사가 제공하는 신청서
        양식에 필요한 정보를 기입하고 본 약관에 동의한 후 해당 신청서를 회사에
        제출해야 합니다. 이 모든 과정은 당사 플랫폼 ‘리뷰하고 돈벌자’를 통해
        온라인으로만 이루어집니다.
      </p>
      <p>
        ② 회사는 본 조 ①항에서 등록신청자에게 신원확인을 위한 절차를 요구할 수
        있으며, 법인사업자의 경우 신원확인과 관련된 자료를 제출하도록 합니다.
        회사는 필요한 경우 등록신청자에게 추가 정보의 제공을 요청할 수 있습니다.
      </p>
      <p>
        ③ 회사는 본 조 ①항을 따라 등록신청자가 해당 서류를 회사에 제출하면 이를
        심사한 후 승인 여부를 결정합니다. 승인이 되면 회사는 그 결과를
        등록신청자(담당자)에게 이메일로 통보합니다. 등록신청자가 이를 확인하면
        이용계약이 성립합니다.
      </p>
      <p>
        ④ 만 19세 이상의 법인사업자는 본 조 ①항의 등록신청자 자격을 갖습니다.
        등록신청자는 법인명(법인 상호)으로 등록신청을 해야 하며 다른 사업자의
        상호를 도용하는 경우 등록이 거부 또는 취소되거나 관련 법령에 따라
        처벌받을 수 있습니다. 만 19세 이상 개인사업자의 등록신청과 만 14세~만
        19세 미만 개인사업자 또는 법인사업자의 등록신청을 허용할지 여부는 회사의
        정책에 따라 결정됩니다(만 19세 미만의 경우, 회사가 법정후견인 동의서 등
        관련 자료를 요청하면 이에 적극 협조해야 합니다).
      </p>
      <p>
        ⑤ 회사는 아래 각호의 경우 등록신청을 거부할 수 있습니다. 또는 등록신청을
        승인한 후에라도 아래 각호의 사항이 확인된 경우 등록신청 승인을 취소할 수
        있습니다. <br />1. 등록신청자가 만 19세 미만인 경우(법인사업자와
        개인사업자 모두 해당) <br />2. 등록신청자가 실명으로 등록신청을 하지
        않은 경우 <br />3. 등록신청자가 회사가 요청한 서류를 제출하지 않거나
        제공한 정보가 허위, 누락, 오타 등으로 부정확한 경우 <br />4.
        등록신청자가 제공한 주요 정보(성명, 주민등록번호, 사업자등록번호 등)가
        이미 등록된 다른 판매자의 정보와 동일한 경우 <br />5. 등록신청자가
        회사와의 계약 내용 또는 관련 법령 등을 위반해 자격 정지나 이용계약 해지
        등을 당한 경우 <br />6. 등록신청자가 램 가게를 탈퇴한 후 2개월 이내에
        다시 등록신청서를 제출한 경우 <br />7. 기술적으로 회사가 등록신청자의
        등록신청을 승인하기 어려운 경우 <br />8. 그 외 등록신청자의 등록신청이
        부당하거나 불법이라고 회사가 판단하는 경우
      </p>
      <p>
        ⑥ 등록신청이 승인된 판매자 회원은 자신의 입점사 관리자 툴에 즉시
        정산받을 은행 계좌번호를 입력하고 계좌사본을 제출해야 합니다. 해당
        정보가 변경되었을 때에도 동일한 절차를 밟아야 합니다. 아울러 판매자
        회원은 주소지나 전화번호 등 판매자의 등록정보에 변경이 있을 경우에도
        즉시 회사가 정한 방식으로 해당 정보를 변경하거나 해당 내용을 회사에
        통지해야 합니다. 회사는 통지의 지연으로 발생한 손해에 대해 책임지지
        않습니다.
      </p>
      <p>
        ⑦ 회사는 ‘전자상거래법’ 제13조 ①항을 따라, 판매자 정보를 판매자가
        판매하는 제품/서비스 상세페이지에 게시합니다. 따라서 판매자 회원은
        판매자 등록정보를 변경할 때 허위 정보를 제공하거나 특정 정보를
        누락해서는 안 되며 사업자등록증 사본 등 회사에 관련 자료를 즉시 제공해야
        합니다.
      </p>
      <p>
        ⑧ 판매자는 판매자(판매자와 관련된 제3자 포함)가 회사에 부정확한 정보나
        관련 자료를 제공함으로써 판매자(판매자와 관련된 제3자 포함)가 입은
        손해에 대해 스스로 책임을 져야 하며, 그 손해에 대해 회사를 면책시켜야
        하고, 그로 인해 회사에 손해를 입힌 경우 회사에 배상해야 합니다.
      </p>
      <p>
        ⑨ 판매자는 판매자가 램 플랫폼에서 거래하는 모든 상품/서비스에 대해 늘
        최신의 정보를 등록, 제공해야 합니다. 등록정보가 변경될 경우, 판매자가
        당사의 플랫폼에서 해당 정보를 변경하지 않아 발생하는 손해에는 본 조
        ⑧항의 원칙이 동일하게 적용됩니다.
      </p>
      <p>
        ⑩ 회사는 판매자가 등록한 정보의 정확성을 확인하기 위해 관련 법령이
        허락하는 범위 안에서 판매자에게 관련 자료의 제공을 요청할 수 있습니다.
      </p>
      <p>
        ⑪ 판매자가 정당한 사유 없이 관련 자료를 제공하지 않는 경우, 회사는
        서비스 사용 제한, 결제금액 지급 보류, 계약의 해지 등을 포함해 필요한
        조치를 취할 수 있습니다. 이 경우, 발생하는 손해는 판매자가 부담합니다.
      </p>
      <br />
      <h6>제7조 (판매자에 대한 통지)</h6>
      <p>
        ① 회사는 판매자가 등록한 이메일 주소나 전화번호, 소재지 주소, 램
        플랫폼의 메시지/알림 기능 등을 통해 판매자에게 알려야 할 사항을
        판매자에게 통지할 수 있습니다.
      </p>
      <p>
        ② 회사는 모든 판매자들을 대상으로 할 경우, 램 가게 판매자센터의 게시판에
        7일 이상 통지할 내용을 게시함으로써 본 조 ①항의 규정을 갈음할 수
        있습니다.
      </p>
      <p>
        ③ 판매자는 실제 연락이 가능한 이메일 주소나 전화번호, 소재지 주소 등의
        정보를 회사에 제공하고 해당 정보들을 최신으로 유지해야 합니다.
      </p>
      <p>
        ④ 판매자는 본 조 ①항과 ②항을 따라 통지된 내용을 확인해야 하며, 판매자의
        의무 소홀에 의해 발생한 불이익은 판매자 자신이 부담합니다.
      </p>
      <br />
      <h6>제8조 (이용계약의 기간 및 종료)</h6>
      <p>
        ① 이용계약의 기간은 본 약관 제6조 ③항을 따라 이용계약이 성립한 시점부터
        본 약관에 명시된 대로 회사 또는 판매자가 계약을 해지한 시점까지입니다.
      </p>
      <p>
        ② 회사는 아래 각호의 사유가 발생한 경우 판매자 회원에게 이용계약의
        해지를 통지함으로써 즉시 이용계약을 해지할 수 있습니다. <br />1.
        판매자가 본 약관, 램 가게 운영정책, 개별약관 또는 관련 법령을 위반하거나
        회사로부터 그 시정을 요구받은 후 7일 이내에 이를 시정하지 않은 경우
        <br />2. 판매자가 관련 법령을 위반하거나 판매자의 책임 있는 사유로
        회사의 명예를 실추하는 등 회사에 유․무형의 손해를 입힌 경우 <br />3.
        판매자가 부도 등 금융기관의 거래정지, 회생 및 파산절차의 개시, 영업정지
        및 취소 등의 행정처분, 주요 자산에 대한 보전처분, 영업양도 및 합병
        등으로 이용계약을 더는 이행할 수 없는 경우 <br />4. 판매자가
        상품/서비스를 등록, 게시할 때 상품/서비스 상세 정보를 잘못 기재하거나
        기재하지 않아서 소비자에게 혼란과 불만을 야기하는 경우 <br />5. 판매자의
        귀책사유로 2개월 동안의 거래 건 중 20% 이상 구매자로부터 이의가 제기된
        경우 <br />6. 판매자에게 본 약관 제6조 ⑤항의 승인 거부 사유가 있음이
        확인된 경우 <br />7. 그 외 판매자의 귀책사유로 회사가 판매자와의
        이용계약을 해지할 필요가 있다고 판단한 경우
      </p>
      <p>
        ③ 회사는 본 조를 따라 판매자와의 계약을 해지할 경우, 본 약관 제7조를
        따라 판매자에게 해당 내용을 통지합니다. 필요한 경우 회사는 기간을 정해
        판매자에게 소명할 기회를 부여합니다. 소명 기회의 부여 여부는 각각의 경우
        회사의 판단에 의해 결정됩니다.
      </p>
      <p>
        ④ 판매자가 회사와의 이용계약을 해지하려 할 경우, 판매자는 아래 각 호의
        조건을 충족시켜야 합니다. 아래 조건이 충족되지 않으면 이용계약 해지가
        유보됩니다. <br />1. 판매자는 램 가게 구매자와의 모든 거래를 종료해야
        합니다. 진행 중인 거래, 판매상품의 취소, 반품, 교환, 구매확정 보류 등이
        모두 0건이어야 합니다. 램 가게 판매자센터의 모든 거래 항목을 0으로
        만들어주세요. <br />2. 판매자가 리뷰 거래소에서 거래 중일 경우, 먼저
        리뷰 거래소의 거래를 모두 종료해주세요. <br />3. 판매자가 리뷰 거래소
        거래를 위한 포인트를 보유하고 있는 경우, 포인트를 모두 소진하거나
        인출해주세요. 포인트 운영 여부는 회사의 정책에 따라 결정됩니다. <br />4.
        판매자가 운영기준 위반으로 이용정지 처리된 경우, 먼저 이에 대해 위반
        사실이 없음을 증명해야 합니다. 증명하기 전에는 이용계약을 해지할 수
        없습니다.
      </p>
      <p>
        ⑤ 본 조 ④항의 조건을 모두 충족하는 경우, 판매자는 언제든지 회사와의
        이용계약을 해지할 수 있습니다.
      </p>
      <p>
        ⑥ 이용계약 해지는 ‘리뷰하고 돈벌자’의 {마이페이지❯설정(✲) 창❯[계정 및
        채널 관리]의 ‘판매자 채널 삭제 신청하기’}를 통해 판매자가 신청하고
        회사가 본 조 ④항의 이용계약 해지 조건을 확인한 후 승인함으로써
        이루어집니다.
      </p>
      <p>
        ⑦ 판매자가 램 가게 판매자센터의 상품/서비스 거래정보를 본 조 ④항의
        조건에 모두 맞추고 회사와의 이용계약을 해지한 후라도, 판매자와 구매자
        사이에 배송, 교환, 반품, 환불 등과 관련해 실재 완결되지 않은 거래 건이
        남아있는 경우 판매자는 신의성실의 원칙에 따라 구매자와의 거래를 완결해야
        합니다. 완결하지 않아 발생하는 분쟁에 대해서는 판매자가 그 책임을
        부담합니다.
      </p>
      <p>
        ⑧ 본 조에 따라 이용계약을 해지하기 전에 발생한 양 당사자의 권리관계 및
        손해배상 청구권은 이용계약 해지 후에도 그대로 유지됩니다.
      </p>
      <br />
      <h6>제9조 (서비스의 중단)</h6>
      <p>
        ① 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 시스템
        업그레이드, 천재지변이나 국가비상사태 등 불가항력의 사유로 인한 통신두절
        등의 경우에는 서비스의 제공을 일시 중단할 수 있습니다.
      </p>
      <p>
        ② 회사는 본 조 ①항의 사유로 서비스의 제공이 일시적으로 중단되어 회원이
        입은 손해에 대해, 회사가 고의 또는 중대한 과실이 없는 한 책임을 지지
        않습니다.
      </p>
      <p>
        ③ 회사는 판매자가 아래 각 호 중 하나 이상에 해당하는 경우, 사전통지 없이
        판매자의 서비스 이용을 제한 또는 중단할 수 있습니다. <br />1. 판매자가
        이 약관, 램 가게 운영정책, 개별약관의 판매자 의무사항을 위반하는 행위를
        하는 경우 <br />2. 판매자가 이 약관, 램 가게 운영정책, 개별약관 등을
        위반하여 회사 및 다른 회원에게 손해가 발생하거나 발생할 우려가 있다고
        판단되는 경우 <br />3. 판매자의 서비스 이용으로 인해 전체 서비스
        시스템에 과부하가 발생하는 경우 <br />4. 판매자에 대해 수사기관의 요청이
        있거나, 방송통신심의위원회, 서울시전자상거래센터 등으로부터 서비스 제한
        요청 등이 있는 경우
      </p>
      <p>
        ④ 회사는 서비스의 원활한 운영을 위해 판매자의 등록 상품/서비스에 판매
        이력이 없거나 판매자의 정보관리 이력이 없는 경우, 전자우편 등으로 통지한
        후 판매자의 등록 상품/서비스 정보를 삭제할 수 있습니다.
      </p>
      <p>
        ⑤ 회사는 사업 종목의 전환, 사업의 종료, 회사의 폐업, 합병, 분할 등의
        이유로 서비스를 제공할 수 없게 되는 경우, 제7조에 정한 방법으로 회원에게
        통지합니다. 이 경우 회원이 보유한 포인트나 받아야 할 정산액 등 회원의
        권한에 속한 사항 외에 회사는 추가로 회원에게 보상하지 않습니다.
      </p>
      <p>
        ⑥ 회사는 필요한 경우 정기 점검을 실시할 수 있으며, 정기 점검 시간은
        고객센터 공지사항란이나 입점사 공지 게시판, 메시지/알림, 전자우편 등을
        통해 사전 공지합니다.
      </p>
      <p>
        ⑦ 회사는 기술적 장애 등의 사유가 있을 경우, 서비스 중단에 대한
        사전통지를 사후 통지로 대신할 수 있습니다.
      </p>
      <br />
      <h6>제10조 (인프라 사용료)</h6>
      <p>
        ① 인프라 사용료는 제휴사나 판매자 회원이 회사가 제공하는 서비스를
        이용하는 대가로 회사에 지급해야 하는 금액을 의미합니다.
      </p>
      <p>
        ② 회사가 제휴사나 판매자 회원에게 부과하는 인프라 사용료는 아래와
        같습니다.
        <br />1. 램 가게 및 램 가게 판매자센터 인프라 사용료 <br />2. 기타 부가
        서비스 등의 인프라 사용료
      </p>
      <p>
        ③ 회사는 시스템 운영 비용, 사업 유형, 상품/서비스 품목, 거래 방법 등을
        감안해 상품/서비스 카테고리별로 인프라 사용료를 책정할 수 있습니다.
      </p>
      <p>
        ④ 회사는 정산대금을 판매자 회원에게 지급할 때 판매대금에서 공제하는 방식
        등으로 인프라 사용료를 수취할 수 있으며, 요율이나 수취 방법 등은 회사의
        정책에 따라 결정됩니다.
      </p>
      <p>
        ⑤ 회사는 인프라 사용료 유형, 요율, 수취 방법, 세금계산서 발행 등에 관한
        구체적인 사항을 램 가게 운영정책에 명시하고 이를 램 가게 판매자센터 전용
        게시판에 게시합니다.
      </p>
      <p>
        ⑥ 회사는 필요한 경우 인프라 사용료를 신설, 변경할 수 있으며, 서비스
        유형별로 인프라 사용료에 관한 계산 및 수취 방법을 다르게 정할 수
        있습니다. 이 경우 신설 또는 변경된 사항을 램 가게 판매자센터 전용
        게시판에 게시합니다.
      </p>
      <p>
        ⑦ 램 가게 및 램 가게 판매자센터에 등록된 상품/서비스에 부과되는 인프라
        사용료는 정가가 아닌 판매가가 기준입니다. 이 기준은 회사의 정책에 따라
        달라질 수 있습니다.
      </p>
      <br />
      <h6>제11조 (회사와 판매자 간의 관계, 권리 부여 등)</h6>
      <p>
        ① 회사와 판매자 회원은 본 약관에 의한 계약관계로서 상호 독립적입니다.
        판매자 회원은 회사의 합작법인이나 프랜차이즈 지점, 피고용인, 독점
        사업권자 등이 아닙니다.
      </p>
      <p>
        ② 판매자 회원은 회사의 사전 서면 동의 없이 임의로 회사의 상호, 상표,
        로고 등을 사용하거나 본 조 ①항에 규정된 범위를 넘어 회사와의 관계를
        왜곡, 과장, 표시해서는 안 됩니다.
      </p>
      <p>
        ③ 본 약관과 램 가게 운영정책, 개별약관은 회사와 판매자 회원 간에만
        유효하며, 제3자는 이와 관련해 그 어떤 권리나 청구 등을 주장할 수
        없습니다.
      </p>
      <p>
        ④ 본 약관과 관련 법령의 범위 안에서, 판매자 회원은 회사가 램 가게
        판매자센터 및 램 플랫폼을 원활히 운영하기 위해 필요한 모든 사항들에 대해
        회사에 권리를 부여합니다.
      </p>
      <p>
        ⑤ 본 약관과 관련 법령의 범위 안에서, 판매자 회원은 램 플랫폼 이용과정의
        산물인 데이터와 정보의 소유권/사용권이 회사에 있음을 인정하고 이에
        동의합니다.
      </p>
      <p>
        ⑥ 본 약관과 관련 법령의 범위 안에서, 판매자 회원은 판매자 회원이
        고객센터나 램 가게 판매자센터 등을 통해 회사에 제공한 아이디어, 개선사항
        등을 회사가 공개, 수정, 재생산, 배포, 사용할 수 있음을 인정하고 이에
        동의합니다.
      </p>
      <p>
        ⑦ 회사는 판매자 회원의 불법행위에 대해, 또는 판매자 회원의 불법행위가
        의심되는 경우, 또는 구매자 등의 신고나 소송 제기 등으로 판매자 회원의
        불법행위가 의심되는 경우 이에 대한 수사 등을 의뢰하기 위해 관련 자료를
        수사기관, 법원 등에 제출할 수 있으며, 판매자 회원은 이를 인정하고
        동의합니다.
      </p>
      <p>
        ⑧ 회사는 수사기관이 수사목적을 위해 또는 관계 기관이 관련 법령에 따라
        판매자 회원의 서비스 이용 자료를 요청하는 경우, 판매자 회원의 동의 없이
        관련 자료를 해당 기관에 제공할 수 있습니다.
      </p>
      <p>
        ⑨ 회사는 램 플랫폼의 안정적인 서비스 운영을 위해 판매자 회원의 판매
        상품/서비스 한도를 정할 수 있으며, 그 기준은 램 가게 운영정책을
        따릅니다.
      </p>
      <p>
        ⑩ 회사는 램 플랫폼의 기능, 디자인, 서비스 내용 등 시스템 전반에 걸쳐
        언제든 이를 수정, 변경, 삭제, 신설할 수 있습니다.
      </p>
      <p>
        ⑪ 회사는 판매자 회원이 제기하는 불편사항이나 서비스 개선사항이
        정당하다고 판단하는 경우, 해당 문제를 해결하기 위해 최선을 다합니다.
      </p>
      <p>
        ⑫ 회사는 이 약관이 정하는 바에 따라 판매자 회원에게 지속적이고
        안정적으로 서비스를 제공하기 위해 최선을 다합니다.
      </p>
      <br />
      <h6>제12조 (회사와 판매자 간 윤리지침의 준수)</h6>
      <p>
        ① 회사와 판매자 회원은 공정하고 투명한 그리고 지속가능한 계약관계의
        이행을 위해 아래의 윤리지침을 준수합니다. <br />1. 회사의 임직원은 어떤
        경우에도 판매자 회원을 비롯한 이해당사자들에게 금품, 선물, 향응, 편의,
        접대 등을 요구하거나 받지 않습니다. <br />2. 회사의 임직원은 어떤
        경우에도 판매자 회원을 비롯한 이해당사자들에게 자신들의 가족이나 친구를
        위한 금품, 선물, 경조사비, 사례 등을 요청하거나 받게 하지 않습니다.
        <br />3. 판매자 회원을 비롯한 이해당사자들은 회사와의 계약을 이행하는
        과정에서 회사의 임직원에게 금품, 선물, 향응, 편의, 접대 등을 요구하거나
        제공하지 않습니다. <br />4. 판매자 회원을 비롯한 이해당사자들은 회사의
        임직원이 금품, 선물, 향응, 편의, 접대 등을 요구하면서 고의적으로 업무를
        지연하는 등 해당 당사자에게 불이익을 주는 경우 회사에 즉시
        신고합니다(legasa@reviewnmakemoney.com). <br />5. 다만, 사회적인 통념상
        용인될 수 있는 범위의 제공이나 수수는 예외적으로 인정됩니다. 예외적인
        경우는, ‘홍보나 행사 기념품’, ‘간소한 수준의 식음료’ 등입니다.
      </p>
      <p>
        ② 회사 또는 판매자 회원이 본 조 ①항을 위반하는 경우, 피해 당사자는
        윤리지침을 위반한 상대방 당사자에 대해 그 위반내용의 경중에 따라 거래
        중지, 계약 해지 등의 조치를 취할 수 있으며, 그로 인한 책임은 모두
        윤리지침을 위반한 가해 당사자가 부담합니다.
      </p>
      <p>
        ③ 본 조 ②항의 경우, 윤리지침을 위반한 가해 당사자는 거래 중지, 계약 해지
        등의 조치로 인한 손실이나 손해에 대해 일체의 손해배상청구 및 기타
        민․형사상의 책임, 행정상의 책임을 피해 당사자에게 제기하지 않습니다.
      </p>
      <br />
      <h6>제13조 (회사의 판매자 개인정보 보호)</h6>
      <p>
        ① 회사는 판매자의 개인정보를 수집할 경우, 서비스 제공을 위해 필요한
        범위에서 판매자의 동의를 얻어 최소한의 개인정보를 수집합니다.
      </p>
      <p>
        ② 회사는 판매자의 개인정보를 수집․이용할 때 판매자에게 그 목적을
        고지하고 동의를 받습니다.
      </p>
      <p>
        ③ 회사는 수집된 개인정보를 목적 외의 용도로 이용하지 않으며, 새로운
        이용목적이 발생한 경우 또는 제3자에게 제공하는 경우 이용․제공 단계에서
        판매자 본인에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령이
        달리 정하는 경우는 예외로 합니다.
      </p>
      <p>
        ④ 회사는 판매자의 개인정보 처리를 제3자에게 위탁하는 경우, 위탁 관련
        세부 사항을 공개합니다.
      </p>
      <p>
        ⑤ 그 외 사항은 ‘램 이용약관 제17조 (개인정보보호)’와 회사의
        ‘개인정보처리방침’을 따릅니다.
      </p>
      <br />
      <h6>제14조 (판매자의 보증)</h6>
      <p>
        ① 판매자는 본 약관과 관련 법령을 따라 합법적으로 본 계약을 체결하며, 본
        약관, 램 가게 이용약관, 개별약관에 규정된 판매자의 의무사항을 모두
        이행하고, 관련 법령 및 규정을 준수함을 진술 및 보증합니다.
      </p>
      <p>
        ② 판매자는 ‘부정청탁 및 금품등 수수의 금지에 관한 법률’ 등 국내외
        부패방지 관련 법령들을 위반하지 않았음을 그리고 위반하거나 회사가
        위반하도록 원인을 제공하지 않음을 진술 및 보증합니다.
      </p>
      <p>
        ③ 판매자는 대한민국, 미국, 유엔 등의 국제제재 결의안 및 무역 금지 관련
        법령들을 위반하지 않았음을 그리고 위반하거나 회사가 위반하도록 원인을
        제공하지 않음을 진술 및 보증합니다. 판매자는 국제제재 적용 대상인
        개인이나 법인, 국가가 제조하거나 제조에 관여한 상품/서비스를 판매하지
        아니함을 진술 및 보증합니다.
      </p>
      <p>
        ④ 판매자는 판매자의 지분을 직간접적으로 50% 이상 소유하고 있는 실질적
        소유주가 국제제재 적용 대상이 아님을 진술 및 보증합니다.
      </p>
      <p>
        ⑤ 판매자는 수입, 수출과 관련해 생산국 및 수출국의 수출 통제 관련 법령
        또는 미국의 수출 및 재수출 통제 관련 법령을 위반하거나 회사가 위반하도록
        원인을 제공할 그 어떤 상품/서비스도 판매하거나 판매를 제안하지 아니함을
        진술 및 보증합니다.
      </p>
      <p>
        ⑥ 판매자가 이용계약을 구성하는 사항들을 불이행하거나 위반함으로 인해
        회사 또는 회사의 임직원에게 청구, 소송, 조사, 벌금, 행정적 조치, 보상,
        손해, 비용 등이 발생할 경우, 판매자는 이로부터 회사와 임직원을
        면책시키고 방어하며 보상해야 합니다.
      </p>
      <p>
        ⑦ 본 조 ⑥항과 관련해 회사가 선제적 조치를 취해야 할 필요가 있다고 판단할
        경우, 회사는 자신의 비용으로 이에 대응 또는 개입할 수 있습니다. 이 경우,
        판매자는 회사의 사전 서면 동의 없이 관련 절차에 관여하거나 어떠한 주장에
        동의하지 말아야 합니다.
      </p>
      <p>
        ⑧ 본 조의 각 항목에 따른 의무는 본 이용계약 및 개별서비스의 종료 후에도
        회사와 판매자에 대해 존속합니다.
      </p>
      <br />
      <h6>제15조 (판매자 회원의 ID 및 비밀번호)</h6>
      <p>
        ① 아이디와 비밀번호에 대한 관리책임은 판매자 회원 본인에게 있습니다.
      </p>
      <p>
        ② 판매자 회원 아이디와 비밀번호는 이용계약 기간에만 램 플랫폼에 한해
        사용 가능합니다.
      </p>
      <p>
        ③ 판매자 회원은 자신의 아이디 및 비밀번호를 제3자에게 제공하거나 제3자가
        이용하게 해서는 안 됩니다.
      </p>
      <p>
        ④ 판매자 회원의 귀책사유로 판매자 회원의 로그인 정보가 분실되거나
        제3자가 사용하는 경우, 회사는 그로 인해 발생하는 손해에 대해 일체
        책임지지 않습니다.
      </p>
      <p>
        ⑤ 회원은 자신의 아이디 및 비밀번호가 도난당하거나 제3자가 사용하고
        있음을 인지한 경우 즉시 회사에 통보하고 회사의 안내에 따라야 합니다.
        이를 회사에 통보하지 않거나 회사의 조치를 따르지 않는 등으로 인해
        발생하는 회원의 손실이나 손해 등에 대한 책임은 회원 본인에게 있습니다.
      </p>
      <br />
      <h6>제16조 (비밀유지)</h6>
      <p>
        ① 판매자 회원은 램 플랫폼 사용 중 인지하거나 취득한 고객 정보 및 서비스
        관련 문서, 기술 및 사업 정보, 노하우 등 일체의 정보를 외부에 유출하거나
        해당 정보를 본 플랫폼을 통한 상품/서비스 거래 등의 본래 목적이 아닌 다른
        목적으로 사용해서는 안 됩니다.
      </p>
      <p>
        ② 판매자 회원은 회사의 서면 동의 없이 회사의 기밀 정보를 복사, 복제,
        가공, 공개, 판매, 제3자 제공 등을 할 수 없으며, 고의 또는 과실로 회사의
        기밀 정보를 제3자에게 유출할 경우, 이로 인해 발생하는 모든 손해에 대해
        책임을 부담합니다.
      </p>
      <p>③ 본 조의 의무는 이용계약 종료 후에도 존속합니다.</p>
      <br />
      <h6>제17조 (지식재산권)</h6>
      <p>
        ① 판매자 회원은 상품/서비스 등의 등록, 게시, 판매 등과 관련해 해당
        정보가 상표권, 특허권, 저작권, 성명권, 초상권 등 제3자의 지식재산권을
        침해하거나 관련 법령을 위반하지 않아야 합니다. 제3자의 지식재산권을
        사용하려 할 경우, 판매자는 정당한 권리자로부터 사용 허락을 받아야
        합니다.
      </p>
      <p>
        ② 판매자 회원이 등록, 게시, 사용한 상품/서비스 등의 정보에 대해 제3자가
        자신의 지식재산권이 침해당했다고 주장할 경우, 회사는 해당 정보의 게시
        중지, 수정, 삭제, 해당 상품/서비스의 판매 중지, 재등록 제한 등의 조치를
        취할 수 있습니다.
      </p>
      <p>
        ③ 본 조 ②항과 관련해, 판매자는 회사에 소명자료를 제출할 수 있으며, 해당
        자료가 법원의 확정판결 등 적법한 자료라고 회사가 인정하는 경우, 회사는
        ②항에서 취한 조치를 해제할 수 있습니다. 다만, 소명자료가 법적인 근거나
        효력이 없는 경우, 회사는 해당 조치를 그대로 유지할 수 있습니다.
      </p>
      <p>
        ④ 판매자 회원이 ‘램 가게 판매자센터’에 등록한 상품/서비스의 이미지,
        상세정보 등은 리뷰나 판매를 위해 램 플랫폼(램 장터 및 램 가게)에
        노출되며, 해당 이미지나 정보 등은 회사가 정한 방법을 따라 회사 또는 램의
        리뷰어 회원에 의해 사용 가능합니다. 사용 범위와 방법, 사용 가능 여부는
        회사의 운영정책에 따라 달라질 수 있습니다.
      </p>
      <p>
        ⑤ 판매자 회원은 램 플랫폼에 등록, 게시, 사용한 상품/서비스 이미지나
        정보와 관련해 제3자로부터 분쟁이 발생하는 경우 회사를 면책시켜야 하며,
        그로 인해 회사에 손해가 발생할 경우 그 손해를 배상해야 합니다.
      </p>
      <br />
      <h6>제18조 (판매자의 고객 개인정보 보호)</h6>
      <p>
        ① 판매자 회원은 램 플랫폼에서 상품 또는 서비스를 거래하기 위해 취득한
        구매회원 등 타인의 개인정보를 본 약관이 정한 목적(배송, 고객 서비스 등)
        이외의 다른 용도로 사용하거나 제3자에게 제공하는 등 외부에 유출해선 안
        되며, 관련 법령에 따라 해당 정보를 철저히 보호해야 합니다.
      </p>
      <p>
        ② 회사는 판매자 회원에게 배송 등의 목적으로 공개한 해당 구매회원의
        개인정보를 상당 기간이 경과한 후 개인정보 보호를 위해 비공개 처리할 수
        있습니다.
      </p>
      <p>
        ③ 판매자 회원이 본 조 ①항을 위반하여 구매회원 등 타인의 개인정보를
        오용하거나 외부에 유출한 경우, 그 책임은 전적으로 판매자가 부담하며
        회사는 그에 대해 책임을 지지 않습니다.
      </p>
      <p>
        ④ 판매자 회원은 회사로부터 제공받은 구매회원 등의 개인정보를 제공받은
        용도를 위해 관계 법령 또는 회사가 정한 기간 보유할 수 있으며, 해당
        기간이 종료된 후 즉시 파기해야 합니다. 또한 해당 정보의 당사자로부터
        직접 파기 요청을 받을 경우 이에 응해야 합니다.
      </p>
      <p>
        ⑤ 판매자 회원이 본 조를 위반하여 구매회원 등으로부터 법적 분쟁이
        발생하는 경우, 판매자 회원은 자신의 노력과 비용으로 회사를 면책시켜야
        하며, 모든 민․형사상의 법적 책임을 부담해야 합니다.
      </p>
      <br />
      <h6>제19조 (램 가게 판매자센터의 이용 및 관리)</h6>
      <p>
        ① 회사가 등록신청자의 램 가게 입점 신청을 승인하고 등록신청자가 이를
        확인하면 등록신청자는 판매자 회원이 되며 판매자 회원으로서 램 플랫폼을
        통해 상품/서비스를 등록 및 판매할 수 있게 됩니다. 이 경우, 판매자 회원은
        상품/서비스 정보를 램 가게 판매자센터에 직접 등록, 관리해야 합니다.
        판매자 회원이 램 가게 판매자센터에서 등록, 관리해야 할 내용은
        상품/서비스의 유형/범주, 상품/서비스의 판매가격, 배송 및 설치 조건, 보증
        및 할인, 부가 상품/서비스, 주문 취소 조건, 교환 및 환불 등입니다.
      </p>
      <p>
        ② 판매자 회원은 인프라 사용료, 배송료, 마진율 등을 감안한 상품/서비스의
        판매가격과 리뷰 거래, 광고, 홍보 등을 포함하는 모든 판매 활동을 스스로
        판단, 결정하며, 회사는 합리적인 사유 없이 판매자의 고유 권한에 개입하지
        않습니다.
      </p>
      <p>
        ③ 판매자 회원은 램 플랫폼을 통한 상품/서비스 판매 및 상품광고 등과
        관련해 ‘전자상거래 등에서의 소비자보호에 관한 법률’, ‘전기통신사업법’,
        ‘전자금융거래법’, ‘여신전문금융업법’, ‘식품위생법’, ‘화장품법’,
        ‘전기용품 및 생활용품 안전관리법’, ‘어린이제품 안전 특별법’, ‘정보통신망
        이용촉진 및 정보보호 등에 관한 법률’, ‘상표법’, ‘저작권법’,
        ‘부가가치세법’ 등의 관련 규정을 준수해야 합니다.
      </p>
      <p>
        ④ 본 조 ③항과 관련해 회사는 판매자가 거래하는 상품/서비스가 관련 법령을
        위반하고 있다고 판단하는 경우, 해당 상품/서비스의 위법 또는 위험 여부를
        최종 확인하기 전에 구매자 보호를 위해 임시로 해당 상품/서비스의 판매를
        금지할 수 있습니다. 다만, 확인 결과 위법 또는 위험 요소가 없는 경우
        지체없이 해당 조치를 해제합니다.
      </p>
      <p>
        ⑤ 판매자 회원은 ‘전자상거래 등에서의 상품 등의 정보제공에 관한 고시(이하
        ’고시‘)’에서 규정한 대로 램 가게 판매자센터에 상품/서비스의 정보를
        정확하게 입력해야 하며 등록 후 변경 사항이 있는 경우 즉시 해당 정보를
        수정/보완해야 합니다. 또한 해당 고시가 변경된 경우, 변경된 고시에 맞춰
        해당 정보를 수정/보완해야 합니다.
      </p>
      <p>
        ⑥ 판매자 회원은 자신이 판매하는 상품/서비스에 특별한 거래 조건이나 추가
        비용이 부과/청구되는 경우, 구매자가 구매 결정을 내리기 전에 이를 쉽게
        인식할 수 있도록 해당 내용을 명시적으로 설명해야 합니다.
      </p>
      <p>
        ⑦ 판매자 회원은 자신이 판매한 상품/서비스의 보증 및 A/S 서비스를 자신의
        책임과 비용으로 시행해야 합니다.
      </p>
      <p>
        ⑧ 판매자 회원은 재고 수량 등 수시로 변동되는 사항과 관련된 데이터를
        적절히 관리해야 하며, 해당 데이터를 허위로 기재하지 말아야 합니다.
      </p>
      <p>
        ⑨ 판매자 회원은 등록한 상품/서비스가 장기간 거래 없이 방치되지 않도록
        해야 합니다. 판매자 회원이 상품/서비스를 등록한 후 1년 이상 해당
        상품/서비스의 거래가 없는 경우, 회사는 별도의 통지 없이 해당 상품/서비스
        정보를 삭제하거나 등록 취소 등의 조치를 취할 수 있습니다. 이는
        검색환경을 개선하고 구매회원에게 편의를 제공하기 위함입니다.
      </p>
      <p>
        ⑩ 판매자 회원은 회사가 서비스 운영에 필요한 정보 및 증빙자료의 제공을
        요청할 경우 이에 성실히 응해야 합니다.
      </p>
      <p>
        ⑪ 판매자 회원은 구매자 회원이 세금계산서 발급 등을 요구할 때 이에 성실히
        응해야 합니다.
      </p>
      <p>
        ⑫ 판매자 회원은 구매자 회원의 문의에 대해 성실, 정확하게 답변해야
        합니다. 판매자 회원의 불성실하고 부정확한 답변으로 인해 발생하는 구매자
        회원의 손해는 판매자 회원이 그 책임을 부담합니다.
      </p>
      <p>
        ⑬ 특정한 인허가 자격이 요구되는 상품/서비스의 경우, 판매자 회원은 해당
        요건을 충족한 후 해당 상품/서비스를 등록, 판매, 광고해야 합니다. 인허가
        자격이 없는 상태에서 해당 상품/서비스를 거래해 발생하는 모든 민․형사상의
        책임은 판매자 회원 본인이 부담합니다.
      </p>
      <p>
        ⑭ 판매자 회원은 담당자 이메일이나 전화번호가 변경되는 경우 즉시 램
        플랫폼에서 회사가 지정한 절차를 따라 해당 정보를 수정, 인증해야 합니다.
      </p>
      <p>
        ⑮ 판매자 회원이 회사가 제공하는 시스템을 사용하지 않고 제3자 프로그램을
        사용하여 상품을 등록하는 경우, 그로 인해 발생하는 기술적 및 법적 문제에
        대해서는 판매자 회원이 부담합니다.
      </p>
      <p>
        ⑯ 판매자 회원은 회사가 램 가게 판매자센터 등을 통해 게시 및 안내하는 ‘램
        가게 운영정책’이나 공지사항 등을 정확히 숙지해야 하며, 이를 오인 또는
        무시해 발생하는 판매자의 손해에 대해 회사는 책임지지 않습니다.
      </p>
      <p>
        ⑰ 회사는 램 가게 판매자센터의 효율적인 운영을 위해 판매자 당
        상품/서비스의 등록 건수를 제한할 수 있습니다. 이 경우 해당 사항은 램
        가게 판매자센터의 게시판에 사전 공지합니다.
      </p>
      <p>
        ⑱ 판매자 회원이 본 조의 각 항목에 명시된 의무를 이행하지 않는 경우,
        회사는 서비스 이용 제한, 판매자의 상품/서비스 판매 중단, 본 이용계약
        해지 등의 조치를 취할 수 있습니다. 또한 그로 인해 회사에 손해가 발생한
        경우, 회사는 해당 판매자 회원에게 손해배상을 청구할 수 있습니다.
      </p>
      <br />
      <h6>제20조 (판매자 회원의 금지행위)</h6>
      <p>
        판매자 회원은 아래 각 호에 해당하는 행위를 직접 하거나 제3자로 하여금
        해당 행위를 하게 해서는 안 됩니다.
      </p>
      <p>
        ① 일반적인 금지사항 <br />1. 램 플랫폼에 음란정보나 거짓정보 등 유해한
        정보를 게시하거나 링크하는 행위 <br />2. 해킹, 서버 공격, 컴퓨터
        바이러스 유포 등 회사와 타인에게 해를 입히는 행위 <br />3. 램 서비스의
        안정적인 운영을 방해할 목적으로 램 플랫폼에 다량의 정보를 게시하거나
        수신자의 의사에 반하는 광고성 정보를 지속적으로 전송하는 행위 <br />4.
        램 가게 판매자센터에 등록된 상품/서비스의 거래와 직접적인 연관이 없는
        이벤트나 홍보를 위해 램 서비스를 이용하는 행위 <br />5. 통신판매 이외의
        목적으로 서비스를 이용하는 행위 <br />6. 램 서비스를 방해하거나 장애를
        일으킬 수 있는 모든 행위 <br />7. 관계 법령을 위반하거나 범죄행위와
        결부되는 모든 행위
      </p>
      <p>
        ② 상품/서비스의 등록/판매 관련 금지사항 <br />1. 실제로는 판매하지 않는
        등의 상품/서비스를 허위로 등록하거나 제조사, 원산지, 브랜드 등
        상품/서비스 정보를 부정확하게 기술하는 행위 또는 표시하지 않는 행위
        <br />2. 검색 가능성이나 상품/서비스의 신뢰도를 높이기 위해 상품/서비스
        등록 시 타인의 상표명이나 로고를 도용하는 등 타인의 권리를 침해하거나
        판매 상품/서비스와 관련 없는 브랜드명, 상품/서비스명 등을 기재해 혼란을
        야기하는 행위 <br />3. 상품/서비스 등록 시 위조품, 모조품, 가품 등을
        진품으로 위장해 등록, 판매하는 행위 <br />4. 자기 자신 또는 제3자의 ID를
        이용해 동일한 상품/서비스를 다른 상품/서비스처럼 중복해 등록하는 행위
        <br />5. 상품/서비스 등록 시 관련 없는 카테고리에 해당 상품/서비스를
        등록하는 등 비정상적인 방법으로 상품/서비스를 등록하는 행위 <br />6.
        실제 재고가 없는 상품 또는 제공이 불가능한 서비스를 재고가 있거나 제공이
        가능한 것처럼 허위로 재고/제공 물량에 등록하는 행위 <br />7.
        상품/서비스의 표시․광고 시 타인이 창작한 이미지나 문구, 사진 등 타인의
        창작물을 무단 도용하는 행위 <br />8. 상품/서비스의 표시․광고 시 연예인
        사진 등 타인의 초상권 및 성명권을 침해하는 행위 <br />9. 상품/서비스의
        표시․광고 시 거짓, 과장, 기만, 비방, 부당한 비교 등 사실과 다르게
        적시함으로써 구매자를 속이는 행위 <br />10. 유통 및 판매가 금지되어 있는
        상품/서비스를 판매하거나 판매 자격을 갖춰야 하는 특정 상품/서비스를 판매
        자격 없이 판매하는 행위 <br />11. 청소년 유해매체물의 경우, 청소년
        유해매체물 표시 의무를 위반한 상태에서 해당 상품/서비스를 판매하는 행위
        <br />12. 회사가 제공하는 결제 시스템을 사용하지 않고 직거래를
        유도하거나 직거래 요청을 수락하는 행위 <br />13. 사회통념상 구매자의
        기대에 현저히 못 미치는 낮은 품질의 상품/서비스를 판매하거나 시장 가격을
        훨씬 상회하는 가격으로 상품/서비스를 판매하는 행위 <br />14. 소비자에게
        손해를 입힐 수 있거나 소비자 불만을 유발할 수밖에 없거나 사회 통념상
        판매가 부적합한 상품/서비스를 판매하는 행위 <br />15. 자신의 귀책사유로
        판매자 자신이 구매자의 주문을 취소해야 하는 상품/서비스에 대해 본인의
        책임을 회피하려 구매자에게 주문 취소를 요구하거나 종용하는 행위
        <br />16. 발송예정일까지 상품을 발송하지 않거나 발송되지 않은 상품에
        대해 허위로 미리 송장번호를 입력하는 등의 행위 <br />17. 반복해서 배송을
        지연하거나 배송사고를 일으키는 행위 <br />18. 램 플랫폼에 판매자 회원의
        연락처, 주소 등을 거짓으로 등록하거나 회사나 구매자가 램 플랫폼에 등록된
        연락처로 연락할 때, 해당 연락을 받지 않는 연락 두절 행위 <br />19.
        구매자나 잠재적 구매자로부터 상품/서비스 문의나 A/S 요청 등을 받을 때
        불성실하게 늑장 고객 응대를 하는 행위 <br />20. 구매자의 개인정보를
        이용해 구매자에게 사적으로 접근하거나 연락하는 등의 개인정보 침해행위
        <br />21. 판매자의 정보를 타인에게 빌려주는 행위 <br />22. 판매자가
        부담해야 할 인프라 사용료를 구매자에게 부담시키는 행위 <br />23. 그 외
        관련 법령 및 본 이용약관, 운영정책, 개별약관 등을 위반하거나 램 플랫폼
        시스템의 목적과 어긋나게 램 서비스를 악용하거나 부당이익을 취하는 행위
      </p>
      <p>
        ③ 판매자는 본 조 ①항과 ②항의 금지행위를 위반할 경우 그에 따른 모든
        책임을 부담합니다.
      </p>
      <p>
        ④ 회사는 본 조 ②항과 관련해 위법행위를 발견할 경우, 사전 통지 없이 해당
        상품/서비스에 대해 노출 제한, 판매 제한, 판매 금지, 거래 중인
        상품/서비스의 거래 취소 등의 조치를 취할 수 있으며, 그로 인해 구매자에게
        발생한 피해에 대해서는 판매자가 그 모든 책임을 부담합니다.
      </p>
      <p>
        ⑤ 회사는 판매자가 본 조 ①항 및 ②항을 위반할 경우, 그 경중에 따라
        판매자의 서비스 이용 제한, 판매자 자격 정지, 이용계약 해지 등의 조치를
        취할 수 있으며, 그로 인해 발생한 손해에 대해 판매자에게 손해배상을
        청구할 수 있습니다.
      </p>
      <br />
      <h6>제21조 (매매 부적합 상품)</h6>
      <p>
        ① 관련 법령에 따라 판매가 금지된 물품이나 판매가 제한된 물품은 본
        플랫폼에 등록/판매할 수 없습니다(다만 판매 제한 물품의 경우, 자격요건을
        갖추고 관련 서류를 제출한 후 회사의 심사 및 승인을 거치면 등록/판매
        가능). 판매가 금지/제한된 물품을 판매하는 경우 그에 대한 책임은 해당
        물품을 등록/판매한 판매자가 전적으로 부담합니다. 판매가 금지/제한된
        물품은 아래와 같습니다. <br />1. 판매 금지 물품: 담배, 마약류 및
        향정신성의약품, 의약품, 도수 있는 안경/선글라스, 콘택트렌즈, 모의 총포,
        군복이나 유사군복 및 군용장구, 음란물, 상표권 침해 물품(짝퉁이나 가품
        등), 저작권 침해 물품(영상이나 음반 등의 복제품 등), 초상권 및 성명권
        침해 물품 및 심의되지 아니한 물품, 안전인증표시 없는 안전인증 대상
        전기용품 또는 공산품, 자율안전 확인 신고의 표시 등이 없는 자율안전 확인
        대상 전기용품 또는 공산품, 어린이보호포장표시가 없는 어린이 보호
        포장대상 공산품, 위해식품, 병든 동물 고기 등, 기준/규격이 정해지지 않은
        화학적 합성품 등, 장물이나 습득한 유실물, 멸종위기 및 보호 야생 동식물
        또는 그 가공품, 야생동물 포획 도구, 살아있는 동물, 철도 승차권이나 사용
        제한 상품권 등 발행자가 전매를 금지한 물품, 유가증권 <br />2. 판매 제한
        물품(판매업 신고/등록/허가/승인 필수): 주류(성인인증 후에만 판매 가능),
        총포, 도검, 화약류, 분사기, 전자충격기, 석궁, 유해화학물질,
        건강기능식품, 의료기기
      </p>
      <p>
        ② 관련 법령에 따라 청소년에게만 판매가 금지된 물품은 등록이 가능하나
        성인인증 후에만 판매가 가능합니다. 해당 물품은 [상품 등록] 시 반드시
        ‘청소년 판매 금지 대상 물품’으로 등록해야 하며([상품 등록❯판매
        정보❯성인인증]란을 반드시 ‘사용’으로 표시할 것), 이를 위반하고 판매할
        경우, 그로 인한 책임은 판매자가 전적으로 부담합니다. 청소년 판매 금지
        대상 물품은 아래와 같습니다. <br />1. 청소년 유해매체물(청소년에게
        부적합한 영화, 게임, 음반 등)이나 청소년 유해약물(주류 등) 등의 청소년
        유해물건
      </p>
      <p>
        ③ 관계 기관이 판매중지나 판매유보를 결정 또는 요청한 상품이나 회사가
        회사의 정책상 판매를 금지한 상품은 등록/판매가 불가능합니다.
      </p>
      <p>
        ④ 회사는 본 조 ①항에서 ③항까지와 관련해 판매자의 상품이 온라인상 유통,
        판매가 적합하지 않다고 판단할 경우 회사의 직권 또는 권리주장자의 요청에
        따라 해당 물품의 등록을 삭제 또는 취소하거나 판매를 중지할 수 있습니다.
      </p>
      <p>
        ⑤ 회사는 판매자가 본 조 ①항에서 ③항까지를 위반할 경우, 그 경중에 따라
        판매자의 서비스 이용 제한, 판매자 자격 정지, 이용계약 해지 등의 조치를
        취할 수 있으며, 그에 대한 책임은 전적으로 판매자가 부담합니다.
      </p>
      <br />
      <h6>제22조 (상품의 배송)</h6>
      <p>
        ① 구매자가 상품 주문 및 결제를 완료하면, 회사는 판매자 회원이 해당 주문
        정보를 확인할 수 있도록 ‘입점사 관리자 툴’에 해당 정보를 제공하고,
        판매자 회원은 해당 주문 정보를 확인해야 하며 해당 주문 상품을 배송 중
        파손되지 않도록 적절한 방법으로 포장한 후 배송의 증명이나 추적이 가능한
        물류대행(택배)업체에 배송을 위탁해 배송해야 합니다.
      </p>
      <p>
        ② ‘전자상거래법’ 제15조 ①항에 따라, 판매자 회원은 구매자의 주문 상품
        결제일로부터 3영업일 이내에 또는 판매자 회원이 별도의 배송조건을 설정한
        경우 그 영업일 이내에 해당 상품의 발송을 완료해야 하며, 램 가게
        판매자센터에 송장번호 등의 발송 관련 정보를 입력하여 발송이 완료되었음을
        증명해야 합니다. 이 경우, 실제 물류대행업체에 배송을 위탁한 상품의
        배송정보만을 입력해야 하며 배송을 위탁하지 않은 상품의 허위 배송정보를
        입력해서는 안 됩니다.
      </p>
      <p>
        ③ 판매자 회원이 본 조 ②항의 기한 내에 상품을 발송하지 않거나, 구매자의
        귀책사유가 아닌 판매자의 배송지 오류 등으로 인해 구매자가 정상적으로
        상품을 받지 못한 경우 이에 대한 책임은 모두 판매자 회원이 부담합니다.
      </p>
      <p>
        ④ 배송과정에서 발생한 상품의 하자에 대해 판매자와 구매자 사이에 분쟁이
        발생하는 경우, 판매자는 자신의 책임하에 성실하게 해당 분쟁을 해결해야
        합니다.
      </p>
      <p>
        ⑤ 판매자 회원은 본 조 ①항과 ②항을 따라 상품을 발송할 때 발송 마지막
        단계에서 구매자의 주문 취소 여부를 확인하기 위해 해당 상품의 주문 정보를
        최종 점검해야 합니다.
      </p>
      <p>
        ⑥ 판매자 회원은 상품 발송 후 영업일 기준 3일 이내 또는 판매자 회원이
        고시한 기일 안에 구매자가 해당 상품을 받을 수 있도록 조치해야 하며,
        구매자가 배송과 관련해 ‘허위 발송’이나 ‘미도착 신고’ 또는 기타 사유로
        회사에 신고할 경우, 이에 대한 회사의 통지에 응해 필요한 조치를 취해야
        합니다. 판매자 회원이 신고 접수일로부터 10일 이내에 필요한 조치를 취하지
        않을 경우, 회사는 해당 거래를 취소하고 구매자에게 환불할 수 있습니다.
      </p>
      <p>
        ⑦ 본 조 ①항에도 불구하고 판매자는 택배 외에 매장수령이나 화물배송 등
        다른 배송 방법을 쇼핑몰에 추가해 사용할 수 있습니다.
      </p>
      <p>
        ⑧ 판매자가 위 ⑦항을 따라 택배 외 다른 방법으로 상품을 배송하는 경우,
        배송과 관련해 구매자로부터 소비자 불만이 접수되면 회사는 판매자에게 해당
        상품의 배송을 입증할 자료의 제공을 요청할 수 있습니다. 이 경우, 판매자는
        요청을 받은 날로부터 1영업일 이내에 해당 자료를 회사에 제출해야 합니다.
      </p>
      <p>
        ⑨ 회사는 언제든지 판매자에게 배송을 입증하는 자료의 제공을 요청할 수
        있습니다. 이 경우, 판매자는 요청을 받은 날로부터 7일 이내에 해당 자료를
        회사에 제출해야 합니다.
      </p>
      <p>
        ⑩ 회사는 제3자와의 업무제휴를 통해 통합 배송 서비스, 해외 배송 서비스
        등을 시행할 수 있습니다.
      </p>
      <p>
        ⑪ 회사는 본 조 ③항 등의 사유로 거래가 취소되는 등 판매자의 책임이 명백한
        경우 판매자에게 결제금액의 지급을 보류하거나 벌점을 부과하는 등의 조치를
        취할 수 있습니다.
      </p>
      <br />
      <h6>제23조 (취소, 반품, 교환, 환불)</h6>
      <p>
        ① ‘전자상거래법’ 제15조 ②항에 따라, 판매자 회원은 구매자가 주문한 상품의
        공급이 불가함을 알게 된 경우, 즉시 해당 사실을 구매자와 회사에 전화로
        통지해야 합니다. 이를 통해 구매자의 동의를 얻어 해당 상품의 주문 건을
        취소해야 하며, 회사로 하여금 해당 상품의 구매대금을 결제일 기준 3영업일
        이내에 구매자에게 환불하거나 환불에 필요한 조치를 취할 수 있도록 해야
        합니다.
      </p>
      <p>
        ② 구매자의 주문 상품 결제일로부터 3영업일 이내에 또는 판매자 회원이
        별도의 배송조건을 설정한 경우 그 영업일 이내에 판매자 회원이 해당 상품을
        발송(출고처리)하지 않아 구매자가 해당 상품의 주문 취소를 요청하는 경우
        회사는 해당 주문 건의 결제를 취소하고 환불 처리합니다. 다만, 앞에 명시된
        영업일 이후에 해당 상품의 전부 또는 일부가 배송 중인 경우에는 취소가
        아닌 반품절차에 의해 처리됩니다.
      </p>
      <p>
        ③ ‘전자상거래법’ 제17조 ①항에 따라 구매자는 상품을 배송받은 날로부터 7일
        이내에 또는 상품의 공급이 시작된 날로부터 7일 이내에 판매자에게 반품
        요청을 할 수 있으며, 판매자 회원은 이 기간 안에 반품이나 교환 요청이
        있을 경우 해당 요청에 따라 반품이나 교환을 해주어야 합니다. 판매자가
        구매자의 교환, 반품에 대한 사실확인 진행절차에 적극적으로 임하지 않거나
        지연하는 경우, 회사는 구매자에게서 교환이나 반품의 원인을 파악한 후
        정당한 사유가 있으면 해당 거래를 취소하고 보관 중인 결제대금을
        구매자에게 환불할 수 있습니다.
      </p>
      <p>
        ④ 본 조 ③항에도 불구하고, ‘전자상거래법 제17조’ ②항에 따라, 다음 각 호는
        예외로 하며 판매자 회원이 다음 각 호를 따라 구매자의 청약 철회를
        제한하고자 할 경우, 해당 내용을 상품 상세페이지나 제품의 포장 등
        구매자가 쉽게 알 수 있는 곳에 게재해야 합니다. <br />1. 구매자의
        귀책사유로 인해 상품 등이 훼손된 경우(다만, 구매자가 상품 등의 내용을
        확인하기 위해 포장 등을 훼손한 경우에는 예외) <br />2. 구매자의 사용
        또는 일부 소비로 상품 등의 가치가 현저히 감소한 경우 <br />3. 시간이
        지나 재판매가 어려울 정도로 상품 등의 가치가 현저히 감소한 경우 <br />4.
        복제가 가능한 상품 등의 포장을 훼손한 경우 <br />5. 서비스 또는
        디지털콘텐츠의 제공이 개시된 경우(다만, 가분적 서비스 또는 가분적
        디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에
        대해서는 청약을 철회할 수 있다) <br />6. 주문에 따라 개별적으로 생산되는
        상품의 경우처럼 청약철회 등을 인정하면 판매자에게 회복할 수 없는 중대한
        피해가 예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 그 사실을
        고지하고 구매자의 서면(전자문서를 포함)에 의한 동의를 받은 경우 <br />7.
        그 밖에 거래의 안전을 위하여 대통령령이 정하는 경우
      </p>
      <p>
        ⑤ 본 조 ③항과 ④항의 규정에도 불구하고, ‘전자상거래법 제17조 ③항에 따라,
        상품 등의 내용이 표시․광고 내용과 다르거나 계약 내용과 다르게 이행된
        경우, 구매자는 해당 상품 등을 공급받은 날로부터 3개월 이내, 그 사실을 안
        날 또는 알 수 있었던 날로부터 30일 이내에 반품이나 교환 요청을 할 수
        있으며 판매자 회원은 반품이나 교환을 해주어야 합니다.
      </p>
      <p>
        ⑥ 판매자 회원은 반품 상품을 수령한 경우, 즉시 램 가게 판매자센터 입점사
        페이지 [주문❯반품리스트]에서 해당 상품을 [반품 완료]로 처리함으로써
        판매자의 상품 수령일로부터 3영업일 이내에 회사가 환불 또는 환불에 필요한
        조치를 취할 수 있도록 해야 합니다.
      </p>
      <p>
        ⑦ 본 조 ⑥항에도 불구하고, 판매자 회원은 구매자의 귀책사유로 구매자에게
        반품 배송비를 청구해야 하거나 사은품을 반환받아야 하는 등의 경우, 해당
        문제가 해결될 때까지 해당 상품의 반품처리를 유보할 수 있습니다. 또한
        구매자의 반품/교환 요청이 본 조 ④항에 해당하는 경우 판매자 회원은
        반품/교환을 거부할 수 있습니다. 두 경우 모두 판매자 회원은 해당 상품
        수령 후 [반품 완료] 처리를 하지 않고 회사에 환불 보류를 요청할 수
        있으며, 회사는 환불을 보류할 수 있습니다.
      </p>
      <p>
        ⑧ 구매자가 [구매확정]을 하면 해당 상품에 대한 정산이 시작되고 그
        정산대금은 판매자에게 지급될 것이므로 해당 거래 건은 [램 가게
        판매자센터]의 시스템 안에서 사실상 완결된 것으로 간주됩니다. 따라서
        [구매확정] 이후의 반품 및 환불 건은 해당 당사자인 판매자와 구매자가 직접
        해결해야 합니다. 해당 상품의 반품/환불이 완료된 경우 판매자는 해당
        정보(반품/환불한 상품의 주문번호, 상품명, 수량, 환불한 금액, 주문한 사람
        성명, 환불 증빙자료)를 회사의 메일 주소(info@reviewnmakemoney.com)로
        통보해야 합니다.
      </p>
      <p>
        ⑨ 판매자 회원은 램 가게 판매자센터나 상품 Q&A, 전화 등을 통한 반품
        요청을 수시로 확인하고 합당한 조치를 취해야 합니다. 판매자 회원의
        부주의로 반품 요청을 확인하지 않는 경우, 이는 전적으로 판매자 회원의
        과실이며, 반품 요청을 확인하지 못했다는 핑계로 구매자의 반품 요청 등을
        거부해서는 안 됩니다.
      </p>
      <p>
        ⑩ 구매자가 교환 또는 반품신청을 한 날로부터 14일이 지날 때까지 이미
        수령한 상품을 판매자에게 반환하지 않거나 전화, 전자우편 등으로 연락이
        되지 않는 경우, 해당 구매자의 교환 또는 반품 신청은 효력을 상실합니다.
      </p>
      <p>
        ⑪ 반품이나 교환에 필요한 배송비와 기타 필요한 비용은 귀책사유가 있는
        당사자가 부담합니다. 즉 상품 등의 하자나 오배송으로 인한 반품이나 교환의
        경우에는 판매자가, 구매자의 단순 변심으로 인한 반품이나 교환의 경우에는
        구매자가 그 비용을 부담합니다. 판매자는 판매자의 귀책사유로 인한
        반품이나 교환 배송비를 구매자에게 부담시켜서는 안 됩니다.
      </p>
      <p>
        ⑫ 판매자 회원은 자신이 판매한 상품에 하자가 있거나 사용상 안전에 문제가
        있는 등의 경우, 이를 전량 회수하여 수리, 교환, 환불하는 ‘리콜’ 조치를
        취해야 하며, 이와 관련한 모든 비용을 부담해야 합니다. 또한 판매자는
        타인의 지식재산권을 침해한 불법 상품을 판매한 경우, 이를 전량 회수,
        폐기해야 하며, 이와 관련된 모든 비용을 부담해야 합니다.
      </p>
      <p>
        ⑬ 판매자 회원은 상품 주문일로부터 3개월 이내에 해당 주문 건의 배송,
        취소, 반품, 교환 처리가 종료될 수 있도록 합당한 조치를 취해야 합니다.
        만약 해당 기일 안에 주문 건이 정상적으로 처리되지 않는 경우, 회사는 해당
        주문을 임의로 구매확정 또는 주문 취소 및 환불 처리할 수 있습니다. 이
        경우 회사는 해당 처리일 15일 전에 관련 내용을 판매자 회원에게 통보해야
        하며, 판매자 회원은 이에 이의를 제기할 수 있습니다.
      </p>
      <p>
        ⑭ 본 조 ⑬항의 처리에 대해 구매자가 이의를 제기할 경우 판매자 회원은
        신의성실의 원칙에 따라 이 문제를 해결해야 합니다.
      </p>
      <br />
      <h6>제24조 (상품 판매대금의 정산)</h6>
      <p>
        ① 회사는 램 가게 판매자센터 입점사 관리자 툴에 판매자의 판매내역,
        판매금액, 공제금액, 정산금액 등 정산과 관련된 세부 정보를 제공합니다.
      </p>
      <p>
        ② 램 서비스를 통해 판매된 상품이 ‘구매확정’ 또는 ‘자동구매확정’이 되면,
        회사는 회사의 정산일정 및 운영정책에 따라 판매대금에서 인프라 사용료 및
        미납금 등 상계 청구할 수 있는 상당액을 공제한 정산대금을 판매자 회원에게
        지급합니다.
      </p>
      <p>
        ③ 정산대금은 판매자 회원이 자신의 입점사 관리자 툴에 등록한 계좌로
        지급됩니다. 해당 입금 계좌의 예금주는 판매자 회원과 동일인이어야 함을
        원칙으로 합니다. 판매자 회원이 램 가게를 탈퇴한 이후에 발생하는
        정산대금은 판매자 회원이 자신의 입점사 관리자 페이지에 최종적으로 등록한
        계좌로 지급됩니다.
      </p>
      <p>
        ④ 폐업한 판매자는 램 가게를 통해 상품을 판매해선 안 됩니다. 이 경우
        회사는 판매한 상품의 판매대금을 정산해 주지 않습니다.
      </p>
      <p>
        ⑤ 판매자는 회사에 통보한 폐업 예정일에 앞서 또는 폐업 통보 없이
        사업자등록을 말소해서는 안 됩니다. 이로 인해 회사가 인프라 사용료의
        세금계산서를 적절히 발행하지 못해 가산세를 납부해야 할 경우, 회사는
        판매대금에서 해당 인프라 사용료와 가산세 상당액을 공제한 나머지를
        판매자에게 정산대금으로 지급할 수 있습니다.
      </p>
      <br />
      <h6>제25조 (정산의 보류)</h6>
      <p>
        ① 회사는 판매자 회원의 귀책사유로 회사 또는 구매자에게 손해 등이
        발생하는 경우, 판매대금 정산 시 이에 필요한 제비용을 공제한 후
        정산대금을 판매자에게 지급할 수 있습니다.
      </p>
      <p>
        ② 판매자 회원이 램 가게를 탈퇴하는 경우, 회사는 판매자의 최근 3개월 동안
        월평균 매출의 30%에 상당하는 금액을 공제한 후 남은 잔액을 판매자에게
        지급할 수 있습니다. 이는 이용계약 해지 이전에 판매자가 판매한 상품에
        대해 이후 3개월 동안 발생할 환불, 교환 요청 등의 추가적인 지급 비용으로
        사용하기 위함입니다. 3개월 경과 후 추가 비용으로 사용하고 남은 금액은
        판매자 회원이 자신의 입점사 관리자 페이지에 최종적으로 등록한 계좌로
        지급됩니다.
      </p>
      <p>
        ③ 회사는 장기 배송지연 건을 배송완료로 간주하고 해당 주문 건을 종료할 수
        있습니다. 이 경우 추후 발생할 수 있는 환불 건에 대비해 일정 기간
        판매대금의 정산을 유보할 수 있습니다.
      </p>
      <p>
        ④ 판매자 회원의 채권자가 법원에 판매자 회원의 판매대금에 대해 가압류,
        압류 및 추심명령 등을 신청하고 법원이 이를 인용․결정하는 경우, 회사는
        판매자 회원과 채권자 간의 합의 또는 채무액의 변제 등으로 해당 결정이
        해제될 때까지 판매대금의 정산을 중지할 수 있습니다.
      </p>
      <p>
        ⑤ 판매자 회원이 본 약관의 규정을 어기고 부정거래를 하거나 구매자들로부터
        다수의 불만이 접수되는 등의 사유가 발생하거나 법원․수사기관․금융기관
        등의 요청이 있는 경우, 회사는 해당 사실을 판매자에게 통보하고 구매자들의
        보호를 위해 판매대금의 일부 또는 전부의 정산을 일정 기간 유보하거나
        상계할 수 있습니다.
      </p>
      <p>
        ⑥ 본 조의 규정 외에도 ‘특정 금융거래정보의 보고 및 이용 등에 관한 법률’,
        ‘범죄수익은닉의 규제 및 처벌 등에 관한 법률’ 등 판매대금이 범죄수익으로
        의심되는 경우, 회사는 해당 법령에 규정된 대로 해당 기관에 보고 또는
        신고하며 판매대금의 일부 또는 전부의 정산을 일정 기간 유보하거나 상계할
        수 있습니다.
      </p>
      <br />
      <h6>제26조 (이용계약 위반에 따른 제재 및 조치)</h6>
      <p>
        ① 회사는 판매자가 본 이용약관, 램 가게 운영정책, 개별약관, 관련 법령,
        통상적인 상업원칙에 따른 의무 등을 위반한 것으로 판단할 경우, 그에
        합당한 조치를 취할 수 있습니다.
      </p>
      <p>
        ② 판매자 회원은 본 조 ①항과 관련한 회사의 모니터링 업무에 협조해야 하며,
        회사가 판매자 회원에게 본 조 ①항과 관련해 소명을 요청할 경우 판매자
        회원은 2영업일 내에 이를 확인하고 응답해야 하며 회사에 최소한의
        증빙자료를 제출해야 합니다. 다만, 판매자 회원이 소명 기간의 연장을
        요청하고 회사가 받아들인 경우, 판매자는 해당 기한 내에 소명 절차를
        진행할 수 있습니다.
      </p>
      <p>
        ③ 회사가 본 조 ①항과 관련해 취할 수 있는 조치는 다음과 같습니다. 벌점
        부과, 주의, 경고, 특정 서비스 이용 제한, 판매상품의 일부 또는 전부 판매
        중지 또는 금지, 신규 상품 등록 중지 또는 금지, 제재 대상 상품과 동일
        상품 등록 중지 또는 금지, 상품의 표시․광고 차단 또는 삭제, 검색 제한,
        판매자의 위반 사실 공개, 거래 취소 및 환불, 판매자 회원자격 정지,
        이용계약의 해지, 손해배상청구, 재가입 차단 등. (판매자 회원의 규정
        위반으로 인한 거래 취소 시 인프라 사용료는 환불되지 않을 수 있습니다)
      </p>
      <p>
        ④ 본 조 ③항의 조치 후 판매자 회원이 본 조 ①항과 관련해 위반 행위가
        없었음을 입증한 경우, 회사는 이미 시행한 조치를 즉시 해제하며
        원상회복합니다.
      </p>
      <p>
        ⑤ 판매자 회원의 이용계약 위반에 따른 구체적인 제재 절차 및 조치는 ‘램
        가게 운영정책’을 따릅니다.
      </p>
      <br />
      <h6>제27조 (양도의 금지)</h6>
      <p>
        ① 판매자 회원은 회사의 사전 서면 동의 없이 본 약관에 따른 일체의 권리와
        의무를 제3자에게 양도하거나 담보 목적으로 제공할 수 없습니다.
      </p>
      <p>
        ② 회사는 판매자 회원이 본 조를 위반할 경우, 기존 판매자 회원에 대해
        이용계약을 해지할 수 있으며, 해당 판매자 회원 및 양도받은 제3자에 대해
        서비스 제공을 거부할 수 있습니다.
      </p>
      <br />
      <h6>제28조 (손해배상)</h6>
      <p>
        ① 본 이용약관의 이행 및 본 서비스의 사용과 관련해, 당사자 일방 또는
        당사자 일방의 피고용인, 대리인 등의 귀책사유로 상대방 당사자에게 손해가
        발생한 경우, 판매자 회원은 상대방 당사자가 입은 손해를 배상해야 합니다.
        또한 해당 위반 건과 관련해 회사가 대신 관련 당사자에게 손해를 배상해야
        하는 경우, 판매자 회원은 해당 배상액 및 기타 관련 우발 지출(변호사
        비용이나 이자 등)을 회사에 배상해야 하며, 회사는 이에 대해 구상권을
        행사할 수 있습니다.
      </p>
      <p>
        ② 판매자 회원이 본 이용약관, 램 가게 운영정책, 개별약관, 관련 법령 등을
        위반함으로써 회사의 명예훼손, 신뢰도 하락 등 회사에 유무형의 손해를
        초래한 경우, 판매자 회원은 회사에 해당 손해를 배상해야 합니다.
      </p>
      <br />
      <h6>제29조 (회사의 면책)</h6>
      <p>
        ① 회사는 통신판매 당사자가 아닌 통신판매중개자로서 회원 상호 간의
        자유로운 상품 및 서비스의 거래를 위한 시스템을 운영, 관리, 제공할 뿐
        회원을 대리하지 않습니다.
      </p>
      <p>
        ② 본 플랫폼에서 등록 및 거래하는 상품/서비스, 상품/서비스의 정보, 거래에
        관한 의무와 책임은 통신판매 당사자인 판매자 회원에게 있습니다. 해당
        상품/서비스의 거래와 관련해 판매자 회원과 구매자 간에 분쟁이 발생하는
        경우 회사는 그 분쟁에 개입하지 않으며, 해당 분쟁의 해결과 결과에 대한
        책임은 당사자인 판매자 회원이 부담합니다.
      </p>
      <p>
        ③ 회사는 판매자 회원과 구매자 양 당사자 중 일방의 요청이 있을 경우,
        분쟁조정센터(고객센터 포함)를 통해 예외적으로 해당 분쟁에 개입할 수
        있습니다. 이 경우 판매자 회원은 분쟁조정센터 등의 결정을 신의성실의
        원칙에 따라 최대한 존중해야 합니다.
      </p>
      <p>
        ④ 판매자의 상품/서비스 등록 및 거래와 관련해 제3자가 회사를 상대로
        민․형사상 등의 문제를 제기하는 경우, 판매자 회원은 해당 문제를 해결하기
        위해 회사에 적극 협조해야 하며, 이로 인해 회사에 손해가 발생하는 경우
        해당 손해를 배상해야 합니다.
      </p>
      <p>
        ⑤ 판매자 회원은 ‘전자상거래법’ 제13조 ①항을 따라 판매자 회원의 상호,
        대표자 성명, 주소, 전화번호, 전자우편주소 등 판매자의 정보를 램 가게
        판매자센터에 정확히 등록하고 늘 최신의 상태로 정정, 유지해야 하며 회사는
        ‘전자상거래법’ 제20조 ②항을 따라 해당 정보를 구매자가 열람할 수 있도록
        구매자에게 열람 방법을 제공합니다.
      </p>
      <p>
        ⑥ 본 조 ⑤항과 관련해 판매자 회원이 해당 정보를 입력하지 않거나 허위
        정보를 입력해 법적 문제가 발생하는 경우, 그 모든 책임은 판매자 회원이
        부담합니다.
      </p>
      <p>
        ⑦ 회사는 본 조와 관련해 제3자에게 손해를 배상하거나 기타 비용을 지출하는
        경우, 해당 판매자 회원에게 구상권을 행사할 수 있습니다.
      </p>
      <p>
        ⑧ 회사는 권리자가 적법하게 요청하는 경우, 해당 상품/서비스에 관한 정보를
        삭제 또는 수정하거나 해당 상품의 판매를 제한 또는 정지할 수 있으며,
        판매자 회원은 이로 인해 발생하는 손해에 대해 회사에 손해배상을 청구할 수
        없습니다.
      </p>
      <p>
        ⑨ 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 천재지변 등
        불가항력의 상황으로 인한 통신두절 등의 경우에는 통신판매중개 서비스의
        제공을 일시 중단할 수 있으며, 회사에 고의 또는 중대한 과실이 없는 한
        이에 대해 책임지지 않습니다.
      </p>
      <p>
        ⑩ 회사는 판매자 회원이 자신의 개인정보나 구매자의 개인정보를 타인에게
        유출 또는 제공함으로써 발생하는 피해와 손해에 대해 책임지지 않습니다.
      </p>
      <p>
        ⑪ 판매자 회원이 ‘관련 법령, 본 이용약관, 운영정책, 개별약관 등’의
        제․개정안 및 판매회원 공지사항 등의 확인 의무를 게을리해 발생하는
        판매자의 손해에 대해 회사는 책임지지 않습니다.
      </p>
      <br />
      <h6>제30조 (관할법원)</h6>
      <p>
        본 이용약관과 관련해 회사와 판매자 회원 간에 분쟁이 발생하거나 회원 상호
        간에 분쟁이 발생해 회사를 당사자로 소송이 제기될 경우에는 주식회사
        리뷰앤메이크머니의 소재지에 있는 관할법원을 합의관할법원으로 정합니다.
      </p>
      <br />
      <h6>부칙</h6>
      <p>본 약관은 2022년 12월 05일부터 시행합니다.</p>
    </div>
    <!---->
  </div>
</template>

<script>
export default {
  props: {
    isModals: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: false,
    });
  },
  methods: {
    close() {
      if (!this.isModals) {
        this.$router.back();
        return false;
      }
      this.$emit("open", "close");
      this.$store.dispatch("common/setFooter", {
        viewFooter: true,
      });
    },
  },
};
</script>

<style></style>
