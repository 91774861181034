<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_5">
      개인정보 제공 동의(판매자)<router-link to="" @click.native="close"
        ><img src="@/assets/images/sub/close.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="terms_common">
      <h5>
        리뷰앤메이크머니(주)는 [전자상거래 등에서의 소비자 보호에 관한 법률]
        제10조, 제13조, 제20조 제2항에 따라 소비자가 판매자의 정보를 열람할 수
        있도록 판매자의 정보를 램 장터 또는 램 가게 제품 상세페이지에
        공개합니다.
      </h5>

      <p>
        1. 판매자의 개인정보가 공개되는 곳은 판매자의 상품/서비스 정보가
        게시되는 램 장터 또는 램 가게의 제품 상세페이지입니다.
      </p>
      <p>
        2. 제품 상세페이지에 공개되는 판매자의 개인정보는 판매자의 상호, 대표자
        성명, 전화번호, 이메일 주소, 통신판매업신고번호, 사업자등록번호,
        사업장소재지입니다.
      </p>
      <p>
        3. 제품 상세페이지에 공개되는 판매자의 개인정보는 판매자와 구매자 간의
        원활한 거래 진행을 위해 사용됩니다.
      </p>
      <p>
        4. 판매자의 개인정보는 [전자상거래 등에서의 소비자 보호에 관한 법률]
        제6조 제1항과 제3항에 따라 판매자가 상품/서비스의 판매를 중지한 후 6개월
        간 해당 상세페이지에 게시됩니다.
      </p>
      <br />

      <p>
        판매자는 위 개인정보 제공 동의를 거부할 수 있습니다. 그러나 거부할 경우,
        램 가게에 입점하실 수 없습니다.
      </p>
    </div>
    <!---->
  </div>
</template>

<script>
export default {
  props: {
    isModals: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: false,
    });
  },
  methods: {
    close() {
      if (!this.isModals) {
        this.$router.back();
        return false;
      }
      this.$emit("open", "close");
      this.$store.dispatch("common/setFooter", {
        viewFooter: true,
      });
    },
  },
};
</script>

<style></style>
